import { request } from "../api.service";

export const getSingleUserRequest = async (id) => {
  return request(`a-users/single-user/${id}`, "get");
};

export const assignClientToPartnerRequest = (data) => {
  return request(`a-users/assign-to-partner`, "post", data);
};
export const updateUserStatusRequest = (id, data) => {
  return request(`admin/users/a-user/status/${id}`, "patch", data);
};
