import React, { useEffect, useState } from "react";
import ModelForm from "../../Components/ui/forms/ModelForm";
import PageHeader from "../../Components/ui/pageElementes/PageHeader";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import ViewInArIcon from "@mui/icons-material/ViewInAr";
import { downloadSingleModel } from "../../redux/modules/admin/actions";
import { getSingleModelRequest } from "../../api/modules/models";
import { useHttp } from "../../hooks/useHttp";

const FormModelPage = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { http } = useHttp();
  const { loading: adminLoading } = useSelector((state) => state.adminData);
  const [singleModel, setSingleModel] = useState();

  useEffect(() => {
    if (!id) {
      return;
    }
    http(() => getSingleModelRequest(id)).then((res) => {
      setSingleModel(res);
    });
  }, [id]);

  return (
    <div className="w-full h-full flex flex-col justify-around">
      <PageHeader
        iconColor={singleModel?.arReady && "bg-green"}
        subtitle={singleModel?.arReady && "The model is AR ready"}
        title={singleModel?.name || "New Model"}
        Icon={ViewInArIcon}
        action={() => dispatch(downloadSingleModel(id, singleModel.name))}
        buttonTitle="Download Model"
        backLink={"/models"}
        actionIsLoading={adminLoading}
      />
      <ModelForm singleModel={singleModel} />
    </div>
  );
};

export default FormModelPage;
