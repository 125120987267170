import React, { useEffect, useState } from "react";
import Modal from "../../../Components/ui/pageElementes/Modal";
import { useHttp } from "../../../hooks/useHttp";
import { getSubscriptionPlansRequest } from "../../../api/modules/subscriptionPlans";
import LoadingIcon from "../../../Components/ui/icons/LoadingIcon";
import { Elements } from "@stripe/react-stripe-js";
import StripeCheckoutForm from "../StripeCheckoutForm";
import { loadStripe } from "@stripe/stripe-js";

const SUBSCRIPTION_INTERVAL = {
  monthly: {
    key: "monthly",
    label: "Monthly",
  },
  yearly: {
    key: "yearly",
    label: "Yearly",
  },
};

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const SubscriptionPlansModal = ({
  setVisibility,
  visibility,
  title,
  description,
  submitAction,
  isLoading,
}) => {
  const { http } = useHttp();

  const [subscriptionPlans, setSubscriptionPlans] = useState([]);
  const [interval] = useState(SUBSCRIPTION_INTERVAL.yearly.key);
  const [selectedSubscriptionPlan, setSelectedSubscriptionPlan] = useState();
  const [showPaymentForm, setShowPaymentForm] = useState();
  const [stripeClientSecret, setStripeClientSecret] = useState();

  const fetchSubscriptionPlans = () => {
    http(() =>
      getSubscriptionPlansRequest().then((res) => setSubscriptionPlans(res))
    );
  };

  useEffect(() => {
    fetchSubscriptionPlans();
  }, []);

  const options = {
    // passing the client secret obtained from the server
    clientSecret: stripeClientSecret,
  };

  return (
    <Modal visibility={visibility} setVisibility={setVisibility} width={1000}>
      <div className="w-full flex flex-col justify-start items-center flex-1 w-full">
        {showPaymentForm ? (
          <section className="bg-white dark:bg-gray-900">
            <div className="px-4 mx-auto max-w-screen-xl lg:px-6">
              <div className="mx-auto max-w-screen-md text-center">
                <h2 className="mb-4 text-4xl tracking-tight font-extrabold text-gray-900 dark:text-white">
                  Make the payment
                </h2>
              </div>

              <div className="space-y-8 sm:gap-6 xl:gap-10 lg:space-y-0">
                <Elements stripe={stripePromise} options={options}>
                  <StripeCheckoutForm />
                </Elements>
              </div>
            </div>
          </section>
        ) : (
          <section className="bg-white dark:bg-gray-900">
            <div className="px-4 mx-auto max-w-screen-xl lg:px-6">
              <div className="mx-auto max-w-screen-md text-center">
                <h2 className="mb-4 text-4xl tracking-tight font-extrabold text-gray-900 dark:text-white">
                  Choose a subscription for XRETEH account
                </h2>
                <p className="mb-5 font-light text-gray-500 sm:text-xl dark:text-gray-400">
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry.
                </p>
              </div>

              {/* TODO: Client requested that monthly prices should be hidden for now. */}
              {/* <div className="text-center mb-10"> */}
              {/*  <div className="flex justify-center"> */}
              {/*    <div className="form-check form-switch flex items-center justify-center"> */}
              {/*      <span */}
              {/*        style={{ fontSize: "25px" }} */}
              {/*        className="form-check-label inline-block text-gray-600 " */}
              {/*        htmlFor="flexSwitchCheckDefault" */}
              {/*      > */}
              {/*        {interval === SUBSCRIPTION_INTERVAL.monthly.key ? ( */}
              {/*          <b>{SUBSCRIPTION_INTERVAL.monthly.label}</b> */}
              {/*        ) : ( */}
              {/*          SUBSCRIPTION_INTERVAL.monthly.label */}
              {/*        )}{" "} */}
              {/*        &nbsp; */}
              {/*      </span> */}
              {/*      <input */}
              {/*        className="form-check-input appearance-none w-12 h-6 rounded-full float-left align-top bg-white bg-no-repeat bg-contain bg-gray-300 focus:outline-none cursor-pointer shadow-sm" */}
              {/*        type="checkbox" */}
              {/*        role="switch" */}
              {/*        onChange={() => */}
              {/*          setInterval( */}
              {/*            interval === SUBSCRIPTION_INTERVAL.yearly.key */}
              {/*              ? SUBSCRIPTION_INTERVAL.monthly.key */}
              {/*              : SUBSCRIPTION_INTERVAL.yearly.key */}
              {/*          ) */}
              {/*        } */}
              {/*        checked={interval === SUBSCRIPTION_INTERVAL.yearly.key} */}
              {/*        id="flexSwitchCheckDefault" */}
              {/*      /> */}
              {/*      <span */}
              {/*        style={{ fontSize: "25px" }} */}
              {/*        className="form-check-label inline-block text-gray-600 " */}
              {/*        htmlFor="flexSwitchCheckDefault" */}
              {/*      > */}
              {/*        &nbsp;{" "} */}
              {/*        {interval === SUBSCRIPTION_INTERVAL.yearly.key ? ( */}
              {/*          <b>{SUBSCRIPTION_INTERVAL.yearly.label}</b> */}
              {/*        ) : ( */}
              {/*          SUBSCRIPTION_INTERVAL.yearly.label */}
              {/*        )} */}
              {/*      </span> */}
              {/*    </div> */}
              {/*  </div> */}
              {/* </div> */}

              <div className="space-y-8 lg:grid lg:grid-cols-3 sm:gap-6 xl:gap-10 lg:space-y-0">
                {(subscriptionPlans || []).map((subscriptionPlan) => (
                  <div
                    key={subscriptionPlan.id}
                    className=" flex flex-col p-6 mx-auto max-w-lg text-center text-gray-900 bg-white rounded-lg border border-gray-100 shadow dark:border-gray-600 xl:p-8 dark:bg-gray-800 dark:text-white"
                  >
                    <h3 className="mb-4 text-2xl font-semibold">
                      {subscriptionPlan.name}
                    </h3>
                    <p className="font-light text-gray-500 sm:text-lg dark:text-gray-400">
                      {subscriptionPlan.description}
                    </p>
                    {!subscriptionPlan.isTrial && (
                      <div className="flex justify-center items-baseline my-8">
                        <span className="mr-2 text-5xl font-extrabold">
                          ${subscriptionPlan[interval].price}
                          {interval === SUBSCRIPTION_INTERVAL.yearly.key && (
                            <p className="mt-4 text-xs text-hex-32454E leading-normal  text-left">
                              You save 15% ( $
                              {(
                                subscriptionPlan.monthly.price * 12 -
                                subscriptionPlan.yearly.price
                              ).toFixed(2)}
                              )
                            </p>
                          )}
                        </span>
                        <span className="text-gray-500 dark:text-gray-400">
                          /{SUBSCRIPTION_INTERVAL[interval].label}
                        </span>
                        <br />
                      </div>
                    )}

                    <ul role="list" className="mb-8 space-y-4 text-left">
                      <li className="flex items-center space-x-3">
                        <svg
                          className="flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fillRule="evenodd"
                            d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                            clipRule="evenodd"
                          ></path>
                        </svg>
                        <span>
                          Disk Quota: <b>{subscriptionPlan.diskQuota} MB</b>
                        </span>
                      </li>
                    </ul>
                    <a
                      href="#"
                      onClick={() => {
                        console.log("get started");
                        setSelectedSubscriptionPlan(subscriptionPlan);
                        submitAction(
                          subscriptionPlan,
                          interval,
                          (paymentIntentClientSecret) => {
                            console.log("payment needed callback called");
                            setStripeClientSecret(paymentIntentClientSecret);
                            setShowPaymentForm(true);
                          }
                        );
                      }}
                      style={{ background: "#139ce0", color: "white" }}
                      className="flex justify-center text-white bg-primary-600 hover:bg-primary-700 focus:ring-4 focus:ring-primary-200 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:text-white  dark:focus:ring-primary-900"
                    >
                      {isLoading &&
                        selectedSubscriptionPlan?.id ===
                          subscriptionPlan?.id && <LoadingIcon />}
                      Get started
                    </a>
                  </div>
                ))}
              </div>
            </div>
          </section>
        )}
      </div>
    </Modal>
  );
};
export default SubscriptionPlansModal;
