import React, { Fragment, useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import PageHeader from "../../Components/ui/pageElementes/PageHeader";
import GroupIcon from "@mui/icons-material/Group";
import SquareButton from "../../Components/ui/buttons/SquareButton";
import RemoveModal from "../../Components/ui/modals/RemoveModal";
import { useHttp } from "../../hooks/useHttp";
import {
  deletePartnerTierRequest,
  getPartnerTiersRequest,
} from "../../api/modules/partnerTiers";
import { ADMIN_PARTNER_TIER_TABLE_COLUMNS } from "./adminPartnerTiers.constants";

const AdminPartnerTiersList = ({ data }) => {
  const navigate = useNavigate();
  const { http, isLoading } = useHttp();
  const [removeModal, setRemoveModal] = useState(false);
  const [selectedID, setSelectedID] = useState();
  const [partnerTiers, setPartnerTiers] = useState();

  const fetchPartnerTiers = () => {
    http(() => getPartnerTiersRequest().then((res) => setPartnerTiers(res)));
  };

  useEffect(() => {
    fetchPartnerTiers();
  }, []);

  const removeSubmit = async () => {
    await http(() => deletePartnerTierRequest(selectedID));
    fetchPartnerTiers();
  };
  const columns = [
    ...ADMIN_PARTNER_TIER_TABLE_COLUMNS,
    {
      field: "actions",
      headerName: "Actions",
      sortable: false,
      filterable: false,
      headerAlign: "center",
      width: 200,
      renderCell: (params) => (
        <Fragment key={params.id}>
          <SquareButton
            action={() => {
              navigate(`/partner-tiers/${params.id}`);
            }}
            extraClasses={"text-xs"}
            padding={"px-2 py-1"}
            title="Edit"
            fill={true}
          />
          <SquareButton
            padding={"px-2 py-1"}
            extraClasses={"text-xs"}
            action={() => {
              setSelectedID(params.id);
              setRemoveModal(true);
            }}
            title="Delete"
            fill={false}
          />
        </Fragment>
      ),
    },
  ];

  return (
    <div className="w-full h-full flex flex-col justify-around">
      <PageHeader
        Icon={GroupIcon}
        action={() => navigate("/partner-tiers/new")}
        buttonTitle="Add Partner Tier"
      />
      <div className="flex flex-col">
        <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div className="shadowBox overflow-hidden ">
              <div style={{ height: 700, width: "100%" }}>
                <DataGrid
                  loading={isLoading}
                  style={{ border: "0", padding: "10px 20px" }}
                  rows={partnerTiers || []}
                  columns={columns}
                  checkboxSelection={false}
                  pageSize={10}
                  rowsPerPageOptions={[5]}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <RemoveModal
        buttonText="Remove Partner Tier"
        visibility={removeModal}
        setVisibility={setRemoveModal}
        submitAction={removeSubmit}
        title="Are you sure you want to remove this partner tier?"
        description={`This action is irreversible.`}
      />
    </div>
  );
};

export default AdminPartnerTiersList;
