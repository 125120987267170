import React, { useState, useEffect } from "react";

import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import MainButton from "../../Components/ui/buttons/MainButton";
import PageHeader from "../../Components/ui/pageElementes/PageHeader";
import { useSelector, useDispatch } from "react-redux";
import { useDropzone } from "react-dropzone";
import ImageIcon from "@mui/icons-material/Image";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { ControlledInput as Input } from "../../Components/ui/forms/ControlledInput";
import { useHttp } from "../../hooks/useHttp";
import {
  profileDatilsUpdate,
  passwordChange,
} from "../../redux/modules/auth/actions";
import {
  fetchUserData,
  profilePictureUpload,
} from "../../redux/modules/user/actions";
import { Controller, useForm } from "react-hook-form";
import SuccessModal from "../../Components/ui/modals/SuccessModal";
import CountriesAutocomplete from "../../Components/ui/forms/CountriesAutocomplete";
import LoadingIcon from "../../Components/ui/icons/LoadingIcon";
import UserAssignmentModal from "../../Components/ui/modals/UserAssignmentModal";
import {
  assignClientToPartnerRequest,
  getSingleUserRequest,
} from "../../api/modules/users";
import moment from "moment";
import { formatBytes } from "../../helpers/numbers";

let modalOpen = false;

const ProfilePage = () => {
  const { http, isLoading } = useHttp();

  const userAssignmentToken = localStorage.getItem("userAssignmentToken");
  const dispatch = useDispatch();
  const { user, userError } = useSelector((state) => state.userData);
  const currentUserRole = user.role;
  const [clientAssignmentURL, setClientAssignmentURL] = useState("");
  const [profile, setPicture] = useState([]);
  const [showCodeModal, setShowCodeModal] = useState();
  const [showPassword, setShowPassword] = useState(false);
  const [passwordChangeSuccess, setPasswordSuccess] = useState("");
  const [rerender, setRerender] = useState(false);
  const [formData] = useState(new FormData());
  const [codeModal, setCodeModal] = useState(
    localStorage.getItem("userAssignmentToken") !== null
  );
  const [partner, setPartner] = useState();

  const [initData, setInitData] = useState({
    name: "",
    phone: "",
    company: "",
    country: "",
  });

  useEffect(() => {
    setInitData({
      name: user.name,
      phone: user.phone,
      company: user.company,
      country: user.country,
    });
    setValue("name", user.name);
    setValue("phone", user.phone);
    setValue("company", user.company);
  }, [user]);

  useEffect(() => {
    if (!userAssignmentToken) {
      return;
    }

    http(() =>
      getSingleUserRequest(userAssignmentToken).then((res) =>
        res.role === "Partner" ? setPartner(res) : setPartner(null)
      )
    );
  }, [userAssignmentToken]);

  const setModalOpen = (value) => {
    modalOpen = value;
    setRerender(!rerender);
  };

  const { getRootProps, getInputProps, fileRejections } = useDropzone({
    accept: "image/*",
    onDrop: (acceptedFiles) => {
      setPicture(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
    },
  });

  const fileRejectionItems = fileRejections.map(({ file, errors }) => (
    <div key={file.path}>
      <ul>
        {errors.map((e) => (
          <li className="text-red-500 font-bold" key={e.code}>
            {e.code === "file-invalid-type"
              ? "The file must be image"
              : e.message}
          </li>
        ))}
      </ul>
    </div>
  ));
  const fileAccpetedItems = profile.map((file) => {
    return (
      <div className="text-primary font-bold" key={file.path}>
        {file.path}
      </div>
    );
  });

  const callback = () => {
    dispatch(fetchUserData());
    setModalOpen(true);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(fetchUserData());
  }, []);
  useEffect(() => {
    if (profile.length) {
      formData.set("profilePicture", profile[0]);
    }

    profile.forEach((file) => URL.revokeObjectURL(file.preview));
  }, [profile]);

  const callbackPassword = () => {
    setPasswordSuccess("Your password has been change");
    setModalOpen(true);
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const submitSwitch = (data) => {
    if (data.password) {
      dispatch(passwordChange(user._id, data.password, callbackPassword));
    }
    if (!shallowEqual(initData, data)) {
      dispatch(
        profileDatilsUpdate(
          user._id,
          {
            company: data.company,
            name: data.name,
            phone: data.phone,
            currency: data.currency,
          },
          callback
        )
      );
    }
    if (profile && profile.length > 0) {
      dispatch(profilePictureUpload(user.id, formData, callback));
    }
  };

  const formatSubscriptionEndsAt = () => {
    const endDate = moment(user.stripeSubscription?.current_period_end * 1000);
    const duration = moment.duration(endDate.diff(moment()));
    return duration.humanize(true);
  };

  const backendErrors = () => {
    return (userError || []).map((e) => (
      <li className="text-xs text-red-500" key={e.code}>
        {e.message}
        <br />
      </li>
    ));
  };
  function shallowEqual(object1, object2) {
    const keys1 = Object.keys(object1);
    const keys2 = Object.keys(object2);
    if (keys1.length !== keys2.length) {
      return false;
    }
    for (const key of keys1) {
      if (object1[key] !== object2[key]) {
        return false;
      }
    }
    return true;
  }

  const {
    handleSubmit,
    setValue,
    control,
    formState: { errors },
  } = useForm();

  if (!user) {
    return <LoadingIcon />;
  }

  return (
    <div className={"w-full flex-wrap"}>
      <SuccessModal
        visibility={modalOpen}
        setVisibility={setModalOpen}
        title={"The update operation succeeded"}
      />

      <PageHeader
        Icon={ManageAccountsIcon}
        buttonTitle={
          ["userA", "Partner"].includes(user.role) ? "Generate code" : undefined
        }
        action={() => {
          const url = `${window.location.origin}/user-assignment/${user._id}`;
          setClientAssignmentURL(url);
          setShowCodeModal(true);
        }}
      >
        {user.subscription && user.stripeSubscription && (
          <div>
            <div>
              You have used{" "}
              <b>{user.diskUsage ? formatBytes(user.diskUsage) : "0 MB"}</b> out
              of <b>{user.subscription?.subscriptionPlan?.diskQuota} MB</b>.
              Current subscription ends <b>{formatSubscriptionEndsAt()}</b>
            </div>
          </div>
        )}
      </PageHeader>

      <div className="py-2 align-middle inline-block min-w-full w-full">
        <form className="shadowBox overflow-hidden  bg-white px-2 sm:px-6 lg:px-8 py-4 flex flex-col w-full sm:flex-row sm:justify-between flex-wrap">
          <div className="w-full">
            <h3 className="text-lg flex itmes-start font-medium leading-6 text-gray-900">
              Profile picture
            </h3>
            <div className="cursor-pointer w-full flex items-center justify-center flex-col sm:flex-row ">
              <div
                className="bg-white dashed-border flex flex-col items-center jusrify-center rounded-xl w-full my-2"
                {...getRootProps()}
              >
                <p className="text-center px-2 py-1">
                  Drag & Drop files here or Browse Files
                </p>
                <p className="text-center px-2 py-1">File type: JPG, PNG </p>
                <input {...getInputProps()} id="profile-image" />
                {fileRejectionItems}
                {fileAccpetedItems}
                <div className="w-6/12 sm:w-4/12  flex flex-wrap justify-center py-1">
                  <div className="w-28 h-28 rounded-full overflow-hidden  bg-primary flex justify-center items-center ">
                    {profile && profile.length > 0 ? (
                      <img
                        src={profile[0].preview}
                        alt="..."
                        className="w-full h-28 object-cover"
                      />
                    ) : user.profilePicture ? (
                      <img
                        src={`${process.env.REACT_APP_API}${user.profilePicture.usePath}/${user.profilePicture.filename}`}
                        alt="..."
                        className="w-full h-28 object-cover"
                      />
                    ) : (
                      <ImageIcon fontSize="large" className="text-white" />
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="w-full flex items-center justify-center flex-col sm:flex-row ">
              <div className="w-full sm:w-1/2 px-2">
                <h3 className="text-lg flex itmes-start font-medium leading-6 text-gray-900">
                  Email
                </h3>
                <div
                  className={`w-full flex justify-center  items-center py-2 px-7  border border-fade rounded-4xl my-4`}
                >
                  <Input
                    id="profile-email"
                    control={control}
                    fieldName="email"
                    disabled={true}
                    type="email"
                    defaultValue={user?.email}
                    placeholder={"Email"}
                  />
                </div>
                <p
                  className="text-xs text-red-500 "
                  style={{ minHeight: "1rem" }}
                ></p>
              </div>
              <div className="w-full sm:w-1/2 px-2">
                <h3 className="text-lg flex itmes-start font-medium leading-6 text-gray-900">
                  Name
                </h3>
                <div
                  className={`w-full flex justify-center  items-center py-2 px-7  border border-fade rounded-4xl my-4`}
                >
                  <Input
                    id="profile-name"
                    control={control}
                    fieldName="name"
                    type="text"
                    disabled={true}
                    defaultValue={user?.name}
                    placeholder={"Name"}
                    rules={{
                      minLength: {
                        value: 4,
                        message: "The min length of the name is 4",
                      },
                    }}
                  />
                </div>
                <p
                  className="text-xs text-red-500 "
                  style={{ minHeight: "1rem" }}
                >
                  {errors.name && errors.name.message}
                </p>
              </div>
            </div>
            <div className="w-full flex items-center justify-center flex-col sm:flex-row ">
              <div className="w-full sm:w-1/2 px-2">
                <h3 className="text-lg flex items-start font-medium leading-6 text-gray-900">
                  Country
                </h3>
                <div
                  className={`w-full flex justify-center  items-center  px-7  border border-fade rounded-4xl my-4`}
                >
                  <Controller
                    control={control}
                    render={({
                      field: { onChange, onBlur, value, name, ref },
                      fieldState: { invalid, isTouched, isDirty, error },
                      formState,
                    }) => {
                      return (
                        <CountriesAutocomplete
                          disabled={true}
                          onChange={(country) => {
                            onChange(country?.code);
                          }}
                          defaultValue={user?.country}
                        />
                      );
                    }}
                    name="country"
                    rules={{
                      required: {
                        value: false,
                        message: "Country is required",
                      },
                    }}
                  />
                </div>
                <p
                  className="text-xs text-red-500 "
                  style={{ minHeight: "1rem" }}
                >
                  {errors?.country?.message}
                </p>
              </div>
              <div className="w-full sm:w-1/2 px-2">
                <h3 className="text-lg flex itmes-start font-medium leading-6 text-gray-900">
                  Phone
                </h3>
                <div
                  className={`w-full flex justify-center  items-center py-2 px-7  border border-fade rounded-4xl my-4`}
                >
                  <Input
                    id="profile-phone"
                    control={control}
                    fieldName="phone"
                    disabled={true}
                    defaultValue={user.phone}
                    placeholder={"+CC XXX XXX XXX"}
                    type="text"
                    rules={{
                      pattern: {
                        value: /^\+[0-9]{3}-?[0-9]{6,13}$/,
                        message: "Please use a valid phone number",
                      },
                    }}
                  />
                </div>
                <p
                  className="text-xs text-red-500 "
                  style={{ minHeight: "1rem" }}
                >
                  {errors.phone && errors.phone.message}
                </p>
              </div>
            </div>
            {["userA", "Partner"].includes(currentUserRole) && (
              <>
                <div className="w-full">
                  <h2 className="text-gray-900 text-xl leading-tight font-medium mb-2">
                    Company
                  </h2>
                  <hr className="pt-5 mt-5" />
                </div>
                <div className="flex flex-row">
                  <div className="w-full sm:w-1/3 px-2">
                    <h3 className="text-lg flex itmes-start font-medium leading-6 text-gray-900">
                      Name
                    </h3>
                    <div
                      className={`w-full flex justify-center  items-center py-2 px-7  border border-fade rounded-4xl my-4`}
                    >
                      <Input
                        id="partner-company"
                        control={control}
                        disabled={true}
                        fieldName="company.name"
                        type="text"
                        defaultValue={user?.company?.name}
                        placeholder={"Company"}
                        rules={{
                          minLength: {
                            value: 3,
                            message: "The min length of the company name is 3",
                          },
                        }}
                      />
                    </div>
                    <p
                      className="text-xs text-red-500 "
                      style={{ minHeight: "1rem" }}
                    >
                      {errors?.company?.name?.message}
                    </p>
                  </div>
                  <div className="w-full sm:w-1/3 px-2">
                    <h3 className="text-lg flex itmes-start font-medium leading-6 text-gray-900">
                      Address
                    </h3>
                    <div
                      className={`w-full flex justify-center  items-center py-2 px-7  border border-fade rounded-4xl my-4`}
                    >
                      <Input
                        id="partner-company-address"
                        control={control}
                        disabled={true}
                        fieldName="company.address"
                        type="text"
                        defaultValue={user?.company?.address}
                        placeholder={"Company address"}
                        rules={{
                          minLength: {
                            value: 3,
                            message:
                              "The min length of the company address is 3",
                          },
                        }}
                      />
                    </div>
                    <p
                      className="text-xs text-red-500 "
                      style={{ minHeight: "1rem" }}
                    >
                      {errors?.company?.address?.message}
                    </p>
                  </div>
                  <div className="w-full sm:w-1/3 px-2">
                    <h3 className="text-lg flex itmes-start font-medium leading-6 text-gray-900">
                      Representative
                    </h3>
                    <div
                      className={`w-full flex justify-center  items-center py-2 px-7  border border-fade rounded-4xl my-4`}
                    >
                      <Input
                        id="representative"
                        control={control}
                        disabled={true}
                        fieldName="company.representative"
                        type="text"
                        defaultValue={user?.company?.representative}
                        placeholder={"Name"}
                        rules={{
                          minLength: {
                            value: 3,
                            message:
                              "The min length of the representative name is 3",
                          },
                        }}
                      />
                    </div>
                    <p
                      className="text-xs text-red-500 "
                      style={{ minHeight: "1rem" }}
                    >
                      {errors?.company?.representative?.message}
                    </p>
                  </div>
                </div>
                <div className="flex flex-row">
                  <div className="w-full sm:w-1/2 px-2">
                    <h3 className="text-lg flex itmes-start font-medium leading-6 text-gray-900">
                      Registration number
                    </h3>
                    <div
                      className={`w-full flex justify-center  items-center py-2 px-7  border border-fade rounded-4xl my-4`}
                    >
                      <Input
                        id="partner-company-registration-number"
                        control={control}
                        disabled={true}
                        fieldName="company.registrationNumber"
                        type="text"
                        defaultValue={user?.company?.registrationNumber}
                        placeholder={"Registration number"}
                        rules={{
                          minLength: {
                            value: 3,
                            message:
                              "The min length of the company registration number is 3",
                          },
                          maxLength: {
                            value: 30,
                            message:
                              "The max length of the company registration number is 30",
                          },
                        }}
                      />
                    </div>
                    <p
                      className="text-xs text-red-500 "
                      style={{ minHeight: "1rem" }}
                    >
                      {errors?.company?.registrationNumber?.message}
                    </p>
                  </div>
                  <div className="w-full sm:w-1/2 px-2">
                    <h3 className="text-lg flex itmes-start font-medium leading-6 text-gray-900">
                      Taxation number
                    </h3>
                    <div
                      className={`w-full flex justify-center  items-center py-2 px-7  border border-fade rounded-4xl my-4`}
                    >
                      <Input
                        id="partner-company-taxation-number"
                        control={control}
                        disabled={true}
                        fieldName="company.taxationNumber"
                        type="text"
                        defaultValue={user?.company?.taxationNumber}
                        placeholder={"Taxation number"}
                        rules={{
                          minLength: {
                            value: 3,
                            message:
                              "The min length of the company taxation number is 3",
                          },
                          maxLength: {
                            value: 30,
                            message:
                              "The max length of the company taxation number is 30",
                          },
                        }}
                      />
                    </div>
                    <p
                      className="text-xs text-red-500 "
                      style={{ minHeight: "1rem" }}
                    >
                      {errors?.company?.taxationNumber?.message}
                    </p>
                  </div>
                </div>
                <hr className=" w-full pt-5 mt-5" />
              </>
            )}
            <div className="w-full flex items-center justify-center flex-col sm:flex-row ">
              <div className="w-full sm:w-1/2 px-2">
                <h3 className="text-lg flex itmes-start font-medium leading-6 text-gray-900">
                  Password
                </h3>
                <div
                  className={`w-full flex justify-center  items-center py-2 px-7  border border-fade rounded-4xl my-4`}
                >
                  <Input
                    id="profile-password"
                    control={control}
                    fieldName="password"
                    type={showPassword ? "text" : "password"}
                    placeholder={"Password"}
                    rules={{
                      minLength: {
                        value: 4,
                        message: "The min length of the password is 4",
                      },
                    }}
                  />
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                </div>
                <p
                  className="text-xs text-red-500 "
                  style={{ minHeight: "1rem" }}
                >
                  {errors.password && errors.phone.password}
                </p>
                {passwordChangeSuccess !== "" && (
                  <div className="rounded-xl bg-green-100 px-2 py-1 text-green-700 flex justify-center">
                    {passwordChangeSuccess}
                  </div>
                )}
              </div>
              {currentUserRole === "userA" && (
                <>
                  <div className="w-full sm:w-1/2 px-2">
                    <h3 className="text-lg flex itmes-start font-medium leading-6 text-gray-900">
                      Subscribed status
                    </h3>
                    <div className="w-full flex justify-center  items-center py-2 px-7  border border-fade rounded-4xl my-4">
                      <Input
                        id="profile-status"
                        type="text"
                        disabled={true}
                        control={control}
                        fieldName="status"
                        defaultValue={user.subscription?.subscriptionPlan?.name}
                      />
                    </div>
                    <p
                      className="text-xs text-red-500 "
                      style={{ minHeight: "1rem" }}
                    ></p>
                  </div>
                  <div className="w-full sm:w-1/2 px-2">
                    <h3 className="text-lg flex itmes-start font-medium leading-6 text-gray-900">
                      Currency
                    </h3>
                    <div className="w-full flex justify-center  items-center py-5 px-7  border border-fade rounded-4xl my-4">
                      <Controller
                        control={control}
                        render={({
                          field: { onChange, onBlur, value, name, ref },
                          fieldState: { invalid, isTouched, isDirty, error },
                          formState,
                        }) => {
                          return (
                            <select
                              id="profile-currency"
                              fieldName="currency"
                              onChange={onChange}
                              value={value || user.currency}
                            >
                              <option value="eur">EUR</option>
                              <option value="usd">USD</option>
                            </select>
                          );
                        }}
                        name="currency"
                      />
                    </div>
                    <p
                      className="text-xs text-red-500 "
                      style={{ minHeight: "1rem" }}
                    ></p>
                  </div>
                </>
              )}
            </div>
          </div>
          <div className="w-full p-2 flex justify-center  items-center">
            <MainButton
              action={handleSubmit(submitSwitch)}
              title="Save"
              width="w-2/6"
            />
          </div>

          <SuccessModal
            visibility={showCodeModal}
            setVisibility={setShowCodeModal}
            title="Your client assignment URL"
          >
            <a
              className="underline text-blue-600 hover:text-blue-800 visited:text-purple-600"
              href={clientAssignmentURL}
            >
              {clientAssignmentURL}
            </a>
          </SuccessModal>

          {user.role === "userA" && partner && (
            <UserAssignmentModal
              visibility={codeModal}
              setVisibility={setCodeModal}
              isLoading={isLoading}
              title={"Partner Assignment"}
              description={`Partner ${partner?.name} wants to assign you to himself`}
              action={() => {
                assignClientToPartnerRequest({ partnerId: partner.id });
              }}
            />
          )}

          <ul className="w-full flex justify-center items-center">
            {backendErrors()}
          </ul>
        </form>
      </div>
    </div>
  );
};

export default ProfilePage;
