import React from "react";
import PageHeader from "../../Components/ui/pageElementes/PageHeader";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import ModelsList from "../../Components/ui/pageElementes/ModelsList";

const MyModels = () => {
  return (
    <div className="w-full h-full flex flex-col justify-around">
      <PageHeader
        Icon={HomeOutlinedIcon}
        link="/models/new"
        buttonTitle="Upload new model"
      />

      <ModelsList extraLink={"/model"} />
    </div>
  );
};

export default MyModels;
