import moment from "moment";
import FaceIcon from "@mui/icons-material/Face";

export const ADMIN_ASSIGN_END_USERS_TABLE_COLUMNS = [
  {
    field: "profilePicture",
    renderCell: (params) => (
      <div className="flex-shrink-0 h-10 w-10 bg-primary flex justify-center items-center rounded-full overflow-hidden">
        {params.row.profilePicture ? (
          <img
            src={`${process.env.REACT_APP_API}${params.row.profilePicture.usePath}/${params.row.profilePicture.filename}`}
            alt={params.row.name}
            className="object-cover w-full h-full"
          />
        ) : (
          <FaceIcon className="text-white " />
        )}
      </div>
    ),
    headerName: "",
    flex: 0.5,
  },
  {
    field: "name",
    valueGetter: (params) => `${params.row.name}`,
    headerName: "Name",
    flex: 1,
  },
  {
    field: "phone",
    valueGetter: (params) => `${params.row.phone}`,
    headerName: "Phone",
    flex: 1,
  },
  {
    field: "email",
    valueGetter: (params) => `${params.row.email}`,
    headerName: "Email",
    flex: 1.5,
  },
  {
    field: "createdAt",
    valueGetter: (params) => {
      return moment(params.row.createdAt).format("LL");
    },
    headerName: "Join Date",
    flex: 1,
  },
  {
    field: "assignedModels",
    valueGetter: (params) => {
      return params.row.models3D.length;
    },
    headerName: "Assigned Models",
    flex: 1,
  },
];
