import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import ImageIcon from "@mui/icons-material/Image";
import { useDropzone } from "react-dropzone";
import {
  profilePictureUploadEndUser,
  updateEndUserData,
  profileDatilsEndUserUpdate,
  clearError,
} from "../../redux/modules/user/actions";
import { Controller, useForm } from "react-hook-form";
import { useHttp } from "../../hooks/useHttp";
import { getSingleUserRequest } from "../../api/modules/users";
import { ControlledInput as Input } from "../../Components/ui/forms/ControlledInput";
import MainButton from "../../Components/ui/buttons/MainButton";
import SuccessModal from "../../Components/ui/modals/SuccessModal";
import UserAssignmentModal from "../../Components/ui/modals/UserAssignmentModal";
import { assignEndUserToClientRequest } from "../../api/modules/endUser";
import CountriesAutocomplete from "../../Components/ui/forms/CountriesAutocomplete";

let modalOpen = false;
const MyAccountEndUserPage = () => {
  const { http, isLoading } = useHttp();

  const userAssignmentToken = localStorage.getItem("userAssignmentToken");

  const [profile, setPicture] = React.useState([]);
  const { userError, user } = useSelector((state) => state.userData);
  const [rerender, setRerender] = useState(false);
  const dispatch = useDispatch();
  const [codeModal, setCodeModal] = useState(
    localStorage.getItem("userAssignmentToken") !== null
  );
  const [client, setClient] = useState();
  const [initData, setInitData] = useState({
    name: "",
    phone: "",
    email: "",
  });

  useEffect(() => {
    if (profile.length) {
      formData.set("profilePicture", profile[0]);
    }

    profile.forEach((file) => URL.revokeObjectURL(file.preview));
  }, [profile]);

  useEffect(() => {
    dispatch(clearError());
    setInitData({
      name: user.name,
      phone: user.phone,
      email: user.email,
    });
    setValue("name", user.name);
    setValue("phone", user.phone);
    setValue("email", user.email);
  }, [user]);

  useEffect(() => {
    if (!userAssignmentToken) {
      return;
    }

    http(() =>
      getSingleUserRequest(userAssignmentToken).then((res) => setClient(res))
    );
  }, [userAssignmentToken]);

  const { getRootProps, getInputProps, fileRejections } = useDropzone({
    accept: "image/*",
    onDrop: (acceptedFiles) => {
      setPicture(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
    },
  });

  const fileRejectionItems = fileRejections.map(({ file, errors }) => (
    <div key={file.path}>
      <ul>
        {errors.map((e) => (
          <li className="text-red-500 font-bold" key={e.code}>
            {e.code === "file-invalid-type"
              ? "The file must be image"
              : e.message}
          </li>
        ))}
      </ul>
    </div>
  ));
  const fileAccpetedItems = profile.map((file) => {
    return (
      <div className="text-primary font-bold" key={file.path}>
        {file.path}
      </div>
    );
  });

  const {
    handleSubmit,
    setValue,
    control,
    formState: { errors },
  } = useForm();
  const [formData] = useState(new FormData());

  const backendErrors = () => {
    return userError?.map((e) => (
      <li className="text-xs text-red-500" key={e.code}>
        {e.message}
        <br />
      </li>
    ));
  };

  function shallowEqual(object1, object2) {
    const keys1 = Object.keys(object1);
    const keys2 = Object.keys(object2);
    if (keys1.length !== keys2.length) {
      return false;
    }
    for (const key of keys1) {
      if (object1[key] !== object2[key]) {
        return false;
      }
    }
    return true;
  }

  const callback = () => {
    dispatch(updateEndUserData());
    setModalOpen(true);
  };

  const submitSwitch = (data) => {
    if (!shallowEqual(initData, data)) {
      dispatch(
        profileDatilsEndUserUpdate(
          {
            name: data.name,
            phone: data.phone,
            email: data.email,
          },
          callback
        )
      );
    }
    if (profile && profile.length > 0) {
      dispatch(profilePictureUploadEndUser(formData, callback));
    }
  };

  const setModalOpen = (value) => {
    modalOpen = value;
    setRerender(!rerender);
  };

  return (
    <div className="w-full flex justify-center  items-center flex-col">
      <SuccessModal
        visibility={modalOpen}
        setVisibility={setModalOpen}
        title={"The update operation succeeded"}
      />
      <form className="shadowBox overflow-hidden  bg-white px-2 sm:px-6 lg:px-8 py-4 flex flex-col w-full sm:flex-row sm:justify-between flex-wrap">
        <div className="w-full">
          <h3 className="text-lg flex itmes-start font-medium leading-6 text-gray-900">
            Profile picture
          </h3>
          <div className="cursor-pointer w-full flex items-center justify-center flex-col sm:flex-row ">
            <div
              className="bg-white dashed-border flex flex-col items-center jusrify-center rounded-xl w-full my-2"
              {...getRootProps()}
            >
              <p className="text-center px-2 py-1">
                Drag & Drop files here or Browse Files
              </p>
              <p className="text-center px-2 py-1">File type: JPG, PNG </p>
              <input {...getInputProps()} id="profile-image" />
              {fileRejectionItems}
              {fileAccpetedItems}
              <div className="w-6/12 sm:w-4/12  flex flex-wrap justify-center py-1">
                <div className="w-28 h-28 rounded-full overflow-hidden  bg-primary flex justify-center items-center ">
                  {profile && profile.length > 0 ? (
                    <img
                      src={profile[0].preview}
                      alt="..."
                      className="w-full h-28 object-cover"
                    />
                  ) : user.profilePicture ? (
                    <img
                      src={`${process.env.REACT_APP_API}${user.profilePicture.usePath}/${user.profilePicture.filename}`}
                      alt="..."
                      className="w-full h-28 object-cover"
                    />
                  ) : (
                    <ImageIcon fontSize="large" className="text-white" />
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="w-full flex items-center justify-center flex-col sm:flex-row ">
            <div className="w-full sm:w-1/2 px-2">
              <h3 className="text-lg flex itmes-start font-medium leading-6 text-gray-900">
                Email
              </h3>
              <div
                className={`w-full flex justify-center  items-center py-2 px-7  border border-fade rounded-4xl my-4`}
              >
                <Input
                  id="profile-email"
                  control={control}
                  fieldName="email"
                  disabled={true}
                  type="email"
                  defaultValue={user?.email}
                  placeholder={"Email"}
                />
              </div>
              <p
                className="text-xs text-red-500 "
                style={{ minHeight: "1rem" }}
              ></p>
            </div>
            <div className="w-full sm:w-1/2 px-2">
              <h3 className="text-lg flex itmes-start font-medium leading-6 text-gray-900">
                Name
              </h3>
              <div
                className={`w-full flex justify-center  items-center py-2 px-7  border border-fade rounded-4xl my-4`}
              >
                <Input
                  id="profile-name"
                  control={control}
                  fieldName="name"
                  type="text"
                  disabled={true}
                  defaultValue={user?.name}
                  placeholder={"Name"}
                  rules={{
                    minLength: {
                      value: 4,
                      message: "The min length of the name is 4",
                    },
                  }}
                />
              </div>
              <p
                className="text-xs text-red-500 "
                style={{ minHeight: "1rem" }}
              >
                {errors.name && errors.name.message}
              </p>
            </div>
          </div>
          <div className="w-full flex items-center justify-center flex-col sm:flex-row ">
            <div className="w-full sm:w-1/2 px-2">
              <h3 className="text-lg flex items-start font-medium leading-6 text-gray-900">
                Country
              </h3>
              <div
                className={`w-full flex justify-center  items-center  px-7  border border-fade rounded-4xl my-4`}
              >
                <Controller
                  control={control}
                  render={({
                    field: { onChange, onBlur, value, name, ref },
                    fieldState: { invalid, isTouched, isDirty, error },
                    formState,
                  }) => {
                    return (
                      <CountriesAutocomplete
                        disabled={true}
                        onChange={(country) => {
                          onChange(country?.code);
                        }}
                        defaultValue={user?.country}
                      />
                    );
                  }}
                  name="country"
                  rules={{
                    required: {
                      value: false,
                      message: "Country is required",
                    },
                  }}
                />
              </div>
              <p
                className="text-xs text-red-500 "
                style={{ minHeight: "1rem" }}
              >
                {errors?.country?.message}
              </p>
            </div>
            <div className="w-full sm:w-1/2 px-2">
              <h3 className="text-lg flex itmes-start font-medium leading-6 text-gray-900">
                Phone
              </h3>
              <div
                className={`w-full flex justify-center  items-center py-2 px-7  border border-fade rounded-4xl my-4`}
              >
                <Input
                  id="profile-phone"
                  control={control}
                  fieldName="phone"
                  disabled={true}
                  defaultValue={user.phone}
                  placeholder={"+CC XXX XXX XXX"}
                  type="text"
                  rules={{
                    pattern: {
                      value: /^\+[0-9]{3}-?[0-9]{6,13}$/,
                      message: "Please use a valid phone number",
                    },
                  }}
                />
              </div>
              <p
                className="text-xs text-red-500 "
                style={{ minHeight: "1rem" }}
              >
                {errors.phone && errors.phone.message}
              </p>
            </div>
          </div>
        </div>
        <div className="w-full p-2 flex justify-center  items-center">
          <MainButton
            action={handleSubmit(submitSwitch)}
            title="Save"
            width="w-2/6"
          />
        </div>
        <ul className="w-full flex justify-center items-center">
          {backendErrors()}
        </ul>
      </form>
      {client && client.role === "userA" && (
        <UserAssignmentModal
          visibility={codeModal}
          setVisibility={setCodeModal}
          isLoading={isLoading}
          title={"Client Assignment"}
          description={`Client ${client?.name} wants to assign you to himself`}
          action={() => {
            assignEndUserToClientRequest({ clientId: client.id });
          }}
        />
      )}
    </div>
  );
};

export default MyAccountEndUserPage;
