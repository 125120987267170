import React from "react";
import SearchIcon from "@mui/icons-material/Search";
import FilterListTwoToneIcon from "@mui/icons-material/FilterListTwoTone";
import { useDebounce } from "../../hooks/useDebounce";

const SearchField = ({
  action,
  filterRequest,
  showFilterButton,
  setFilters,
  filters,
  searchValue,
  setSearchValue,
  width,
  sortBy,
  sortValue,
  id,
}) => {
  const [showFilters, setShowFilters] = React.useState(false);

  useDebounce(
    () => action(searchValue, filters, sortBy, sortValue),
    [searchValue, filters, sortBy, sortValue],
    300
  );
  const handleChange = (name, val) => (evt) => {
    if (name === "search") {
      setSearchValue(evt.target.value);
    } else if (val) {
      setFilters({ ...filters, [name]: val.value });
    } else {
      setFilters({ ...filters, [name]: evt.target.value });
    }
  };

  const submit = (e) => {
    e.preventDefault();
    action(searchValue, sortBy, sortValue, filters);
  };
  return (
    <div onSubmit={(e) => submit(e)}>
      <div className="flex flex-col justify-center">
        <div className="flex flex-row justify-between	 items-center space-between p-4">
          <div
            className={`border-2 rounded-full w-full ${
              width || "sm:w-2/6"
            } p-2 mb-4 flex`}
          >
            <SearchIcon />

            <input
              id={`${id}-text`}
              placeholder="Type here to search..."
              type={"text"}
              value={searchValue}
              onChange={handleChange("search")}
              className={"focus:outline-none w-full ml-2"}
            />
          </div>
          {showFilterButton && (
            <div
              onClick={() => setShowFilters(!showFilters)}
              className="border-2 rounded-full p-2 mb-4 flex cursor-pointer"
            >
              <FilterListTwoToneIcon />
              Filters
            </div>
          )}
        </div>

        {showFilters && (
          <div
            className="shadowBox flex flex-wrap  flex-row justify-betwee items-center space-between p-4 w-full"
            onSubmit={(e) => submit(e)}
          >
            {/* TODO: Implement customFields filters */}
          </div>
        )}
      </div>
    </div>
  );
};

export default SearchField;
