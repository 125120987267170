import { request } from "../api.service";

export const updateSubscriptionRequest = (data) => {
  return request(`/subscriptions/update`, "post", data);
};

export const cancelSubscriptionRequest = (data) => {
  return request(`/subscriptions/cancel`, "post", data);
};

export const assignSubscriptionRequest = (data) => {
  return request(`/subscriptions/assign`, "post", data);
};
