import React, { useState, useEffect } from "react";
import { useDropzone } from "react-dropzone";
import ImageIcon from "@mui/icons-material/Image";
import { Formik } from "formik";
import { MenuItem, Select, Switch, TextareaAutosize } from "@mui/material";
import { useSelector } from "react-redux";
import MainButton from "../buttons/MainButton";
import { useNavigate, useParams } from "react-router-dom";
import { useModalContext } from "../../../SuccessModalContext";

import { getAllCustomFieldsRequest } from "../../../api/modules/customFields";
import { useHttp } from "../../../hooks/useHttp";
import RemoveModal from "../modals/RemoveModal";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import SecondaryButton from "../buttons/SecondaryButton";
import LoadingIcon from "../icons/LoadingIcon";
import * as Yup from "yup";
import ModelFormCustomFields from "./ModelFormCustomFields";
import { getCategoriesRequest } from "../../../api/modules/categories";
import {
  createModelRequest,
  deleteModelRequest,
  updateModelCoverImageRequest,
  updateModelRequest,
  uploadFinalModelRequest,
} from "../../../api/modules/models";

const ModelForm = ({ singleModel }) => {
  const { id } = useParams();

  const [filesModel, setFilesModel] = React.useState([]);
  const [filesCoverImageZon, setFilesCoverImageZone] = React.useState([]);
  const [subcategories, setSubcategories] = React.useState([]);
  const [removeModal, setRemoveModal] = useState(false);
  const navigate = useNavigate();
  const { http, backendErrors, isLoading } = useHttp();
  const { user } = useSelector((state) => state.userData);
  const [categories, setCategories] = useState([]);
  // TODO: refactor by getting rid of this state
  const [finalResponse, setFinalResponse] = React.useState({
    model: [], // file
    coverImage: [], // img
    name: "",
    otherDetail: "",
    description: "",
    marketplace: false,
  });
  const state = useSelector((state) => state.auth);
  const currentUser = state.user;
  const [fileErrors, setFileErrors] = useState({
    model: "",
    coverImage: "",
  });
  const formData = new FormData();
  const [customFields, setCustomFields] = useState();

  const initialValidations = {
    name: Yup.string().required("This field is Required"),
    category: Yup.string().required("This field is required"),
    subcategory: Yup.string().required("This field is required"),
    description: Yup.string().required("This field is Required"),
  };

  const [yupValidations, setYupValidations] = useState(initialValidations);

  useEffect(() => {
    if (!customFields) return;

    const customFieldsValidations = {};
    customFields?.map((customField) => {
      customFieldsValidations[`customFields_${customField._id}`] =
        Yup.mixed().required("This field is required");
    });

    setYupValidations({
      ...initialValidations,
      ...customFieldsValidations,
    });
  }, [customFields]);

  const { setOpenState } = useModalContext();

  useEffect(() => {
    if (!singleModel) return;

    setSubcategories(singleModel?.category?.subcategories);

    if (singleModel?.customFields?.length) {
      setCustomFields(singleModel?.customFields || []);
    } else if (singleModel?.subcategory) {
      getCustomFieldsBySubCategoryId(singleModel?.subcategory?._id).then(
        (res) => setCustomFields(res)
      );
    } else {
      setCustomFields([]);
    }
  }, [singleModel]);

  React.useEffect(() => {
    if (filesCoverImageZon.length) {
      setFinalResponse((prevState) => ({
        ...prevState,
        coverImage: filesCoverImageZon,
      }));
      filesCoverImageZon.forEach((image) => {
        formData.append("coverImage", image);
      });
    }
  }, [filesCoverImageZon]);

  React.useEffect(() => {
    if (filesModel.length) {
      setFinalResponse((prevState) => ({
        ...prevState,
        model: filesModel,
      }));
      formData.set("model", filesModel[0]);
    }
  }, [filesModel]);

  useEffect(() => {
    http(() => getCategoriesRequest()).then((res) => setCategories(res));
  }, []);

  const callback = () => {
    if (user.role === "Admin") {
      navigate("/models");
    } else if (user.role === "userA") {
      navigate("/my-models");
    }
    setOpenState(true);
  };

  const handleFileUploadsValidations = () => {
    if (id) {
      // it means we are on edit mode,
      // so the file uploads are optional,
      // therefore stop
      return;
    }

    setFileErrors({
      ...fileErrors,
      model: !finalResponse?.model?.length ? "A model is required" : "",
      coverImage: !finalResponse?.coverImage?.length
        ? "A cover image is required"
        : "",
    });
  };

  const prepareCustomFields = (formFields) => {
    const _customFields = [];
    Object.keys(formFields).map((field) => {
      if (!field.startsWith("customFields_")) {
        return;
      }

      const customFieldId = field.replace("customFields_", "");
      const customField = customFields.find(
        (customField) => customField._id === customFieldId
      );
      if (!customField) {
        return;
      }

      _customFields.push({
        ...customField,
        value: formFields[field],
      });
    });

    return _customFields;
  };

  const prepareFormData = (data) => {
    formData.set("name", data.name);
    formData.set("otherDetail", data.otherDetail);
    formData.set("description", data.description);
    formData.set("category", data.category);
    formData.set("subcategory", data.subcategory);

    if (data.customFields) {
      formData.set("customFields", JSON.stringify(data.customFields));
    }

    if (filesModel.length) {
      formData.set("model", filesModel[0]);
    }

    if (filesCoverImageZon.length) {
      formData.set("coverImage", filesCoverImageZon[0]);
    }

    return formData;
  };

  const handleSubmit = async (data) => {
    handleFileUploadsValidations();

    data.customFields = prepareCustomFields(data);

    if (id) {
      if (finalResponse.coverImage && finalResponse.coverImage.length > 0) {
        const formData = new FormData();
        formData.set("coverImage", filesCoverImageZon[0]);
        await updateModelCoverImageRequest(id, formData);
      }
      await http(() => updateModelRequest(id, data)).then(() => callback());
    } else {
      const formData = prepareFormData(data);
      await http(() => createModelRequest(formData)).then(() => callback());
    }
  };

  const { getRootProps, getInputProps, fileRejections } = useDropzone({
    accept: ".obj,.fbx,.OBJ,.FBX,.glb,.GLB,.gltf,.GLTF",
    onDrop: (acceptedFiles) => {
      setFilesModel(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
    },
  });

  const coverImageZone = useDropzone({
    accept: "image/*",
    multiple: true,
    maxFiles: 4,
    onDrop: (acceptedFiles) => {
      setFilesCoverImageZone(
        acceptedFiles.map((file) => {
          return Object.assign(file, {
            preview: URL.createObjectURL(file),
          });
        })
      );
    },
  });

  const fileRejectionItemsModel = fileRejections.map(({ file, errors }) => (
    <div key={file.path}>
      <ul className="w-full text-center">
        {errors.map((e) => (
          <li className="text-red-500 font-bold" key={e.code}>
            {e.code === "file-invalid-type"
              ? "The file must be 3D model"
              : e.message}
            <br />
            {file.path}
          </li>
        ))}
      </ul>
    </div>
  ));

  const fileRejectionItemsCover = () => {
    if (
      coverImageZone.fileRejections &&
      coverImageZone.fileRejections.length > 0
    ) {
      return (
        <div>
          <ul className="w-full text-center">
            {coverImageZone.fileRejections[0].errors.map((e) => (
              <li className="text-red-500 font-bold" key={e.code}>
                {e.code === "file-invalid-type"
                  ? "The file must be image"
                  : e.message}
              </li>
            ))}
          </ul>
        </div>
      );
    }
  };

  const fileAcceptedItemsCover = finalResponse.coverImage.map((file, i) => {
    return (
      <div className="text-primary font-bold" key={file.path}>
        {file.path}
      </div>
    );
  });

  const fileAcceptedItemsModel = finalResponse.model.map((file, i) => {
    return (
      <div className="text-primary font-bold" key={file.path}>
        {file.path}
      </div>
    );
  });

  const showBackendErrors = () => {
    return backendErrors?.map((e) => (
      <li className="text-xs text-red-500 " key={e.code}>
        {e.message}
        <br />
      </li>
    ));
  };

  const removeModel = () => {
    http(() => deleteModelRequest(singleModel._id)).then(() => callback());
  };

  const getCustomFieldsBySubCategoryId = async (subCategoryId) => {
    return http(() => getAllCustomFieldsRequest({ subCategoryId }));
  };

  const onUploadFinalModel = () => {
    const formData = new FormData();
    formData.set("arFile", filesModel[0]);
    http(() => uploadFinalModelRequest(id, formData)).then(() => callback());
  };

  const customFormFields = {};
  customFields?.map((customField) => {
    customFormFields[`customFields_${customField._id}`] = customField.value;
  });

  const initialValues = {
    ...singleModel,
    category: singleModel?.category?._id,
    subcategory: singleModel?.subcategory?._id,
    ...customFormFields,
  };

  if (id && (!singleModel || !customFields)) {
    return <LoadingIcon />;
  }

  return (
    <div className="py-2 align-middle inline-block min-w-full w-full">
      <Formik
        validateOnMount={true}
        initialValues={singleModel ? initialValues : {}}
        onSubmit={handleSubmit}
        validationSchema={Yup.object().shape(yupValidations)}
      >
        {(props) => (
          <form className="shadowBox overflow-hidden bg-white px-2 sm:px-6 lg:px-8 py-4 flex flex-col w-full sm:flex-row sm:justify-between flex-wrap">
            {/* TODO: move model upload to a separate component */}
            <div className="w-full">
              <h3 className="text-lg flex itmes-start font-medium leading-6 text-gray-900">
                Model 3D<span className="text-xs text-red-500">*</span>
              </h3>
              <span className="text-xs text-blue-400">Mandatory field</span>
              <div className="cursor-pointer w-full flex items-center justify-center flex-col sm:flex-row ">
                <div
                  className="bg-white dashed-border flex flex-col items-center jusrify-center rounded-xl w-full my-2 p-12"
                  {...getRootProps()}
                >
                  <div className="uploadModelForm-image">
                    <ImageIcon fontSize="large" color="disabled" />
                  </div>
                  <p className="text-center px-2 py-1">
                    Drag & Drop files here or Browse Files
                  </p>

                  <input {...getInputProps()} id="model-3d-object" />
                  {fileRejectionItemsModel}
                  {fileAcceptedItemsModel}
                  <div className="uploadModelForm-subTitle">
                    {" "}
                    Max size: 2GB | File type: OBJ, FBX, GLB, GLTF{" "}
                  </div>
                  <p
                    className="text-xs text-red-500 "
                    style={{ minHeight: "1rem" }}
                  >
                    {fileErrors.model && fileErrors.model}
                  </p>
                </div>
              </div>
              {id && ["Admin", "Support"].includes(currentUser.role) && (
                <div className="w-full flex justify-center">
                  <MainButton
                    title="Upload final model"
                    type="button"
                    width="w-full sm:w-3/6"
                    isLoading={isLoading}
                    disabled={isLoading || !filesModel?.length}
                    action={() => onUploadFinalModel()}
                  />
                </div>
              )}
              <br />
              <br />
            </div>
            <div className="w-full flex items-center justify-center flex-col sm:flex-row ">
              <div className="w-full sm:w-1/2 px-2">
                <h3 className="text-lg flex itmes-start font-medium leading-6 text-gray-900">
                  Model name
                  <span className="text-xs text-red-500">*</span>
                </h3>
                <span className="text-xs text-blue-400">Mandatory field</span>
                <div
                  className={`w-full flex justify-center  items-center py-2 px-7  border border-fade rounded-4xl my-4`}
                >
                  <input
                    type={"text"}
                    name={"name"}
                    placeholder={"Model name"}
                    onChange={props.handleChange}
                    value={props?.values?.name}
                    className={`flex flex-1 appearance-none w-1/2 py-3 leading-tight focus:outline-none`}
                  />
                </div>
                <p
                  className="text-xs text-red-500 "
                  style={{ minHeight: "1rem" }}
                >
                  {props.errors?.name}
                </p>
              </div>
              <div className="w-full sm:w-1/2 px-2">
                <h3 className="text-lg flex font-medium leading-6 text-gray-900">
                  Other detail
                </h3>
                <span className="text-xs text-blue-400 invisible">
                  Not mandatory field
                </span>
                <div
                  className={`w-full flex justify-center  items-center py-2 px-7  border border-fade rounded-4xl my-4`}
                >
                  <input
                    type={"text"}
                    name={"otherDetail"}
                    placeholder={"Other detail"}
                    onChange={props.handleChange}
                    value={props?.values?.otherDetail}
                    className={`flex flex-1 appearance-none w-1/2 py-3 leading-tight focus:outline-none`}
                  />
                </div>
                <p
                  className="text-xs text-red-500 "
                  style={{ minHeight: "1rem" }}
                >
                  {props.errors?.otherDetail}
                </p>
              </div>
            </div>
            <div className="w-full flex items-center justify-center flex-col sm:flex-row ">
              <div className="w-full sm:w-1/2 px-2">
                <h3 className="text-lg flex itmes-start font-medium leading-6 text-gray-900">
                  Category
                  <span className="text-xs text-red-500">*</span>
                </h3>
                <span className="text-xs text-blue-400">Mandatory field</span>
                <div
                  className={`w-full flex justify-center items-center px-7 border border-fade rounded-4xl my-4`}
                >
                  <Select
                    name={"category"}
                    style={{ width: "100%" }}
                    value={props.values?.category}
                    label="Category"
                    onChange={(e) => {
                      props.handleChange(e);
                      const catId = e.target.value;
                      const catObject = (categories || []).find(
                        (cat) => cat.id === catId
                      );
                      setSubcategories(catObject?.subcategories || []);
                      setCustomFields([]);
                      props.setFieldValue("subcategory", "");
                    }}
                  >
                    {categories?.map((cat) => (
                      <MenuItem value={cat.id} key={cat.id}>
                        {cat.name}
                      </MenuItem>
                    ))}
                  </Select>
                </div>
                <p className="text-xs text-red-500 ">
                  {props.errors?.category}
                </p>
              </div>
              <div className="w-full sm:w-1/2 px-2">
                <h3 className="text-lg flex itmes-start font-medium leading-6 text-gray-900">
                  Subcategory
                  <span className="text-xs text-red-500">*</span>
                </h3>
                <span className="text-xs text-blue-400">Mandatory field</span>
                <div
                  className={`w-full flex justify-center items-center px-7 border border-fade rounded-4xl my-4`}
                >
                  <Select
                    name={"subcategory"}
                    style={{ width: "100%" }}
                    value={props.values?.subcategory}
                    label="Subcategory"
                    onChange={async (e) => {
                      props.handleChange(e);
                      const subCatId = e.target.value;

                      setCustomFields([]);
                      if (subCatId) {
                        const res = await getCustomFieldsBySubCategoryId(
                          subCatId
                        );
                        setCustomFields(res);
                        res?.map((customField) => {
                          props.setFieldTouched(
                            `customFields_${customField._id}`,
                            true
                          );
                        });
                      }
                    }}
                  >
                    {subcategories?.map((subcat) => (
                      <MenuItem value={subcat._id} key={subcat._id}>
                        {subcat.name}
                      </MenuItem>
                    ))}
                  </Select>
                </div>

                <p className="text-xs text-red-500 ">
                  {props.errors?.subcategory}
                </p>
              </div>
            </div>

            <ModelFormCustomFields
              customFields={customFields}
              formikProps={props}
            />

            <div className="w-full px-2">
              <h3 className="text-lg flex itmes-start font-medium leading-6 text-gray-900">
                Client description
                <span className="text-xs text-red-500">*</span>
              </h3>
              <span className="text-xs text-blue-400">Mandatory field</span>

              <TextareaAutosize
                className="textArea"
                aria-label="minimum height"
                minRows={3}
                name={"description"}
                placeholder="A few words about your client..."
                onChange={props.handleChange}
                value={props?.values?.description}
              />
              <p className="text-xs text-red-500 ">
                {props.errors?.description}
              </p>
            </div>
            {/* TODO: Make Model Cover a reusable component */}
            <div className="w-full">
              <h3 className="text-lg flex itmes-start font-medium leading-6 text-gray-900">
                Model Cover
                <span className="text-xs text-red-500">*</span>
              </h3>
              <span className="text-xs text-blue-400">Mandatory field</span>
              <div className="cursor-pointer w-full flex items-center justify-center flex-col sm:flex-row ">
                <div
                  className="bg-white dashed-border flex flex-col items-center jusrify-center rounded-xl w-full my-2"
                  {...coverImageZone.getRootProps()}
                >
                  <p className="text-center px-2 py-1">
                    Drag & Drop files here or Browse Files
                  </p>
                  <p className="text-center px-2 py-1">File type: JPG, PNG </p>
                  <input
                    {...coverImageZone.getInputProps()}
                    id="model-coverImage"
                  />
                  {fileRejectionItemsCover()}
                  {fileAcceptedItemsCover}
                  <div className="flex flex-col sm:flex-row justify-center  items-center">
                    {filesCoverImageZon && filesCoverImageZon.length > 0 ? (
                      filesCoverImageZon.map((image, i) => {
                        return (
                          <div className="w-full" key={i}>
                            <img
                              src={image.preview}
                              alt="..."
                              className="w-full h-full rounded-xl object-cover"
                            />
                          </div>
                        );
                      })
                    ) : singleModel?.coverImages &&
                      singleModel.coverImages.length > 0 ? (
                      singleModel.coverImages.map((image, i) => {
                        return (
                          <div className="w-full" key={i}>
                            <img
                              src={`${process.env.REACT_APP_API}${image.usePath}/${image.filename}`}
                              alt="..."
                              className="w-full h-full rounded-xl object-cover"
                            />
                          </div>
                        );
                      })
                    ) : singleModel?.coverImagePath ? (
                      <>
                        <div className="w-full h-72 p-12 max-w-2xl">
                          <img
                            src={`${process.env.REACT_APP_API}/models3D/content/image/${singleModel._id}/${singleModel.updatedAt}`}
                            alt={singleModel.name}
                            className="w-full h-full object-cover rounded-xl"
                          />
                        </div>
                      </>
                    ) : (
                      <div className="w-full h-full  flex flex-wrap justify-center py-1">
                        <div className="w-28 h-28 rounded-full overflow-hidden  bg-primary flex justify-center items-center ">
                          <ImageIcon fontSize="large" className="text-white" />
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="uploadModelForm-subTitle">
                    {" "}
                    Max size: 5MB | File type: JPG, JPEG, PNG{" "}
                  </div>
                  <p className="text-xs text-red-500 ">
                    {fileErrors.coverImage && fileErrors.coverImage}
                  </p>
                </div>
              </div>
            </div>
            <div className="w-full flex justify-center items-center px-2">
              <h3 className="text-lg flex itmes-start font-medium leading-6 text-gray-900">
                Add this model to marketplace
              </h3>

              <div className={` flex justify-center  items-center`}>
                <Switch
                  name={"marketplace"}
                  defaultChecked={singleModel?.marketplace}
                  onChange={props.handleChange}
                />
              </div>
            </div>
            <div
              className={
                "w-full flex justify-center items-center flex-col py-4"
              }
            >
              <MainButton
                action={props.handleSubmit}
                title="Save"
                type={"submit"}
                isLoading={isLoading}
                width="w-full sm:w-2/6"
                disabled={!props.isValid || isLoading}
              />
              <p className="text-xs text-red-500 w-full text-center py-4 ">
                {fileErrors.model && fileErrors.model}
              </p>
              <ul>{showBackendErrors()}</ul>

              <div
                className={
                  "w-full flex justify-center items-center flex-col py-4"
                }
              >
                {id &&
                  (["Admin", "Support"].includes(currentUser.role) ||
                    singleModel.userId._id === currentUser.id) && (
                    <>
                      {/* TODO: Uncomment this when duplicate modal functionality is needed */}
                      {/* <SecondaryButton */}
                      {/*  Icon={<FileCopyIcon />} */}
                      {/*  action={() => setDuplicateModal(true)} */}
                      {/*  title="Duplicate model" */}
                      {/*  color="text-primary" */}
                      {/*  width="w-full sm:w-2/6" */}
                      {/* /> */}
                      <SecondaryButton
                        Icon={<DeleteForeverIcon />}
                        action={() => setRemoveModal(true)}
                        title="Remove this model"
                        width="w-full :w-2/6"
                      />
                    </>
                  )}
              </div>
            </div>
          </form>
        )}
      </Formik>

      {removeModal && (
        <RemoveModal
          visibility={removeModal}
          setVisibility={setRemoveModal}
          submitAction={removeModel}
          title="Are you sure you want to remove this model?"
          buttonText="Remove model"
          description={`This action is irreversible`}
        />
      )}

      {/* TODO: Uncomment this when duplicate modal functionality is needed */}
      {/* {duplicateModal && <DuplicateModelModal singleModel={singleModel} />} */}
    </div>
  );
};

export default ModelForm;
