import React, { Fragment, useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import PageHeader from "../../Components/ui/pageElementes/PageHeader";
import GroupIcon from "@mui/icons-material/Group";
import SquareButton from "../../Components/ui/buttons/SquareButton";
import RemoveModal from "../../Components/ui/modals/RemoveModal";
import { useHttp } from "../../hooks/useHttp";
import { ADMIN_CLIENTS_TABLE_COLUMNS } from "./adminClients.constants";
import {
  deleteClientRequest,
  getClientsRequest,
} from "../../api/modules/clients";
import AssignModelsToUserModal from "../../Components/ui/modals/AssignModelsToUserModal";
import ActivateUserModal from "../../Components/ui/modals/ActivateUserModal";
import SubscriptionPlansModal from "../../Components/stripe/SubscriptionPlansModal";
import { updateSubscriptionRequest } from "../../api/modules/subscriptions";
import { updateUserStatusRequest } from "../../api/modules/users";

const AdminClientsList = ({ data }) => {
  const navigate = useNavigate();
  const { http, isLoading } = useHttp();
  const [removeModal, setRemoveModal] = useState(false);
  const [showModelAssignmentModal, setShowModelAssignmentModal] =
    useState(false);
  const [showActivateUserModal, setShowActivateUserModal] = useState(false);
  const [showSubscriptionPlansModal, setShowSubscriptionPlansModal] =
    useState(false);
  const [selectedID, setSelectedID] = useState();
  const [clients, setClients] = useState();

  const getClients = () => {
    http(() => {
      getClientsRequest().then((res) => setClients(res));
    });
  };

  useEffect(() => {
    getClients();
  }, [showActivateUserModal]);

  const handleActivateUser = () => {
    http(() => {
      updateUserStatusRequest(selectedID, { status: true });
    });
  };

  const removeSubmit = async () => {
    await http(() => {
      deleteClientRequest(selectedID);
    });

    getClients();
  };

  const columns = [
    ...ADMIN_CLIENTS_TABLE_COLUMNS,
    {
      field: "actions",
      headerName: "Actions",
      sortable: false,
      filterable: false,
      headerAlign: "center",
      width: 200,
      renderCell: (params) => (
        <div
          key={params.id}
          style={{ flexDirection: "column", display: "flex", gap: "10px" }}
        >
          {params.row.status ? (
            <>
              <SquareButton
                padding={"px-2 py-1"}
                extraClasses={`text-xs ${!params.row.status && "invisible"}`}
                action={() => {
                  setSelectedID(params.id);
                  setShowModelAssignmentModal(true);
                }}
                title="Assign Models"
                fill={false}
              />
              <SquareButton
                padding={"px-2 py-1"}
                extraClasses={`text-xs ${!params.row.status && "invisible"}`}
                action={() => {
                  setSelectedID(params.id);
                  setShowSubscriptionPlansModal(true);
                }}
                title="Manage Subscription"
                fill={false}
              />
            </>
          ) : (
            <SquareButton
              padding={"px-7 py-1"}
              extraClasses={`text-xs`}
              action={() => {
                setSelectedID(params.id);
                setShowActivateUserModal(true);
              }}
              title="Activate User"
              fill={false}
            />
          )}
          <SquareButton
            action={() => {
              navigate(`/clients/${params.id}`);
            }}
            extraClasses={"text-xs"}
            padding={"px-2 py-1"}
            title="Edit"
            fill={true}
          />
        </div>
      ),
    },
  ];

  return (
    <div className="w-full h-full flex flex-col justify-around">
      <div className="w-full h-full flex flex-col justify-around">
        <PageHeader
          Icon={GroupIcon}
          action={() => navigate("/clients/new")}
          buttonTitle="Add Client User"
        />
        <div className="flex flex-col">
          <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
              <div className="shadowBox overflow-hidden ">
                <div style={{ width: "100%" }}>
                  <DataGrid
                    loading={isLoading}
                    style={{ border: "0", padding: "10px 20px" }}
                    rows={clients || []}
                    columns={columns}
                    checkboxSelection={false}
                    pageSize={10}
                    autoHeight={true}
                    rowHeight={120}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <RemoveModal
          buttonText="Remove Client"
          visibility={removeModal}
          setVisibility={setRemoveModal}
          submitAction={removeSubmit}
          title="Are you sure you want to remove this client?"
          description={`This action is irreversible.`}
        />

        {showModelAssignmentModal && (
          <AssignModelsToUserModal
            isClient={true}
            visibility={showModelAssignmentModal}
            setVisibility={setShowModelAssignmentModal}
            user={(clients || []).find((client) => client.id === selectedID)}
            onSubmit={() => {
              setSelectedID(null);
              setShowModelAssignmentModal(false);
              getClients();
            }}
          />
        )}
        {showActivateUserModal && (
          <ActivateUserModal
            buttonText="Activate"
            visibility={showActivateUserModal}
            setVisibility={setShowActivateUserModal}
            submitAction={() => {
              handleActivateUser();
              setSelectedID(null);
              setShowActivateUserModal(false);
              getClients();
            }}
            title="Are you sure you want to activate this user?"
          />
        )}

        {showSubscriptionPlansModal && (
          <SubscriptionPlansModal
            buttonText="Submit"
            visibility={showSubscriptionPlansModal}
            setVisibility={setShowSubscriptionPlansModal}
            submitAction={async (
              subscriptionPlan,
              interval,
              initPaymentCallback
            ) => {
              const res = await http(() => {
                // TODO: decide whether to call cancel/upgrade/downgrade/assign
                return updateSubscriptionRequest({ clientId: selectedID });
              });
              console.log({ res });
              if (res?.paymentIntentClientSecret) {
                initPaymentCallback(res?.paymentIntentClientSecret);
              }
            }}
            title="Are you sure you want to activate this user?"
          />
        )}
      </div>
    </div>
  );
};

export default AdminClientsList;
