import { useState } from "react";

export const useHttp = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [toastMessage, setToastMessage] = useState(false);
  const [backendErrors, setBackendErrors] = useState([]);

  const http = async function (makeRequest) {
    setIsLoading(true);

    try {
      const response = await makeRequest();
      setIsLoading(false);

      return response;
    } catch (e) {
      if (e.status === 401) {
        // TODO: fix this when the backend will no longer return 401 for forbidden requests, instead of just unauthorized requests
        // dispatch(
        //   signout(() => {
        //     window.location.href = "/login";
        //   })
        // );
      }

      setToastMessage({
        text: "Something went wrong",
        severity: "error",
      });
      setBackendErrors(e?.data?.errors);
      setIsLoading(false);

      throw e;
    }
  };

  return { http, isLoading, backendErrors, toastMessage };
};
