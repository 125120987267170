import React, { Fragment, useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import PageHeader from "../../Components/ui/pageElementes/PageHeader";
import GroupIcon from "@mui/icons-material/Group";
import SquareButton from "../../Components/ui/buttons/SquareButton";
import { useHttp } from "../../hooks/useHttp";
import { getConnectedEndUsersRequest } from "../../api/modules/authenticatedClient";
import { MY_CLIENTS_TABLE_COLUMNS } from "./myClients.constants";
import AssignModelsToUserModal from "../../Components/ui/modals/AssignModelsToUserModal";

const MyClientsList = ({ setShowModal }) => {
  const navigate = useNavigate();
  const { http, isLoading } = useHttp();
  const [selectedID, setSelectedID] = useState();
  const [endUsers, setEndUsers] = useState();
  const [showModelAssignmentModal, setShowModelAssignmentModal] =
    useState(false);

  const getEndUsers = () => {
    http(() => {
      getConnectedEndUsersRequest().then((res) => setEndUsers(res));
    });
  };

  useEffect(() => {
    getEndUsers();
  }, []);

  const onAssignModalSubmit = () => {
    setSelectedID(null);
    setShowModelAssignmentModal(false);
    getEndUsers();
  };

  const columns = [
    ...MY_CLIENTS_TABLE_COLUMNS,
    {
      field: "actions",
      headerName: "Actions",
      sortable: false,
      filterable: false,
      headerAlign: "center",
      width: 200,
      renderCell: (params) => (
        <Fragment key={params.id}>
          <SquareButton
            padding={"px-2 py-1"}
            extraClasses={"text-xs"}
            action={() => {
              setSelectedID(params.id);
              setShowModelAssignmentModal(true);
            }}
            title="Assign Models"
            fill={false}
          />
          <SquareButton
            action={() => {
              navigate(`/my-client/${params.id}`);
            }}
            extraClasses={"text-xs"}
            padding={"px-2 py-1"}
            title="Edit"
            fill={true}
          />
        </Fragment>
      ),
    },
  ];

  return (
    <div className="w-full h-full flex flex-col justify-around">
      <PageHeader
        Icon={GroupIcon}
        action={() => navigate("/my-client/new")}
        buttonTitle="Add Client"
      />
      <div className="flex flex-col">
        <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div className="shadowBox overflow-hidden ">
              <div style={{ width: "100%" }}>
                <DataGrid
                  loading={isLoading}
                  style={{ border: "0", padding: "10px 20px" }}
                  rows={endUsers || []}
                  columns={columns}
                  checkboxSelection={false}
                  pageSize={10}
                  autoHeight={true}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <AssignModelsToUserModal
        isClient={false}
        visibility={showModelAssignmentModal}
        setVisibility={setShowModelAssignmentModal}
        user={(endUsers || []).find((user) => user.id === selectedID)}
        onSubmit={onAssignModalSubmit}
      />
    </div>
  );
};

export default MyClientsList;
