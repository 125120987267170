import React from "react";
import { MenuItem, Select, Slider } from "@mui/material";

const SidebarFiltersCustomFields = ({ formikProps, customFields }) => {
  return (customFields || []).map((customField, index) => {
    const selectOptions =
      customField.type === "Dropdown List"
        ? customField.dropdownList
        : customField.values;

    return (
      <div
        key={index}
        className="w-full flex items-center justify-center flex-col sm:flex-row "
      >
        <div className="w-full px-2">
          <h3 className="text-lg flex itmes-start font-medium leading-6 text-gray-900">
            {customField.name}
            <span className="text-xs text-red-500">*</span>
          </h3>

          {/* TODO: Move hard-coded string to a constant */}
          {["Dropdown List", "String"].includes(customField.type) && (
            <div
              className={`w-full flex justify-center items-center px-7 border border-fade rounded-4xl my-4`}
            >
              <Select
                multiple={customField.isMultiple}
                name={`customFields_${customField._id}`}
                style={{ width: "100%" }}
                onChange={formikProps.handleChange}
              >
                {selectOptions?.map((option) => (
                  <MenuItem value={option} key={option}>
                    {option}
                  </MenuItem>
                ))}
              </Select>
            </div>
          )}

          {/* TODO: Move hard-coded string to a constant */}
          {customField.type === "Number" && (
            <div className="py-2">
              <div className="flex ml-2 mr-2 ">
                <Slider
                  name={`customFields_${customField._id}`}
                  size="small"
                  value={
                    formikProps.values?.[`customFields_${customField._id}`] || [
                      0,
                      Math.max(customField.value),
                    ]
                  }
                  min={0}
                  max={Math.max(customField.values)}
                  onChange={formikProps.handleChange}
                  valueLabelDisplay="auto"
                />
              </div>
              <div className="flex">
                {formikProps.values?.[`customFields_${customField._id}`]?.map(
                  (value) => (
                    <div
                      key={value}
                      className="border-2 rounded-lg w-full p-2 mx-2 text-center cursor-normal bg-gray-100"
                    >
                      {value}
                    </div>
                  )
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    );
  });
};

export default SidebarFiltersCustomFields;
