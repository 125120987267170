import React from "react";
import { Autocomplete, Box, TextField } from "@mui/material";
import { countries } from "../../../assets/countries";
const CountriesAutocomplete = ({
  onChange,
  defaultValue,
  disabled,
  extraClasses,
}) => {
  return (
    <Autocomplete
      id="signup-country"
      options={countries}
      defaultValue={() => {
        const country = countries.find(
          (country) =>
            country.code === defaultValue || country.label === defaultValue
        );
        onChange(country);
        return country;
      }}
      disabled={disabled}
      onChange={(e) => {
        const country = countries.find((country) =>
          [country.label, country.code].includes(e.target.innerText.trim())
        );

        onChange(country);
      }}
      className={`flex flex-1 appearance-none w-1/2 py-3 ml-5 leading-tight focus:outline-none ${extraClasses}`}
      getOptionLabel={(option) => option.label}
      renderOption={(props, option) => (
        <Box {...props} key={props.id}>
          <img
            loading="lazy"
            width="20"
            src={`https://flagcdn.com/w20/${option.code?.toLowerCase()}.png`}
            srcSet={`https://flagcdn.com/w40/${option.code?.toLowerCase()}.png 2x`}
            alt=""
          />
          &nbsp; {option.label}
        </Box>
      )}
      renderInput={(params) => (
        <TextField
          placeholder="Country"
          {...params}
          variant="standard"
          inputProps={{
            ...params.inputProps,
            autoComplete: "new-password", // disable autocomplete and autofill
          }}
        />
      )}
    />
  );
};
export default CountriesAutocomplete;
