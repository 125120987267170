import React, { useState, useEffect } from "react";
import FaceIcon from "@mui/icons-material/Face";
import ModelsList from "../../Components/ui/pageElementes/ModelsList";
import {
  fetchSingleEndUser,
  singleEndUserUpdate,
  removeEndUser,
  fetchEndUsers,
} from "../../redux/modules/admin/actions";
import { useSelector, useDispatch } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import { ControlledInput as Input } from "../../Components/ui/forms/ControlledInput";
import MainButton from "../../Components/ui/buttons/MainButton";
import { useForm, Controller } from "react-hook-form";
import Empty from "../../Components/ui/pageElementes/Empty";
import PageHeader from "../../Components/ui/pageElementes/PageHeader";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import RemoveModal from "../../Components/ui/modals/RemoveModal";
import SuccessModal from "../../Components/ui/modals/SuccessModal";
import { useModalContext } from "../../SuccessModalContext";
import CountriesAutocomplete from "../../Components/ui/forms/CountriesAutocomplete";
import { useHttp } from "../../hooks/useHttp";
import {
  assignClientstoEndUserRequest,
  createEndUserRequest,
} from "../../api/modules/endUser";
import AssignClientsToEndUserList from "./assignClientsToEndUser.list";
const ViewEndOptions = (props) => {
  const { id } = useParams();
  const isEdit = id !== undefined;
  const dispatch = useDispatch();
  const { http, backendErrors } = useHttp();
  const { singleEndUser } = useSelector((state) => state.adminData);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const { setOpenState } = useModalContext();
  const [removeModal, setRemoveModal] = useState(false);
  const [isActive, setIsActive] = useState(true);
  const [assignedClients, setAssignedClients] = useState([]);

  useEffect(() => {
    window.scrollTo(0, 0);
    isEdit && dispatch(fetchSingleEndUser(id));
  }, []);
  const navigate = useNavigate();
  const callback = () => {
    dispatch(fetchSingleEndUser(id));
    navigate("/end-users");
    setOpenState(true);
  };
  const submit = (data) => {
    http(() => {
      assignClientstoEndUserRequest({ id: id, clientIds: assignedClients });
    });
    dispatch(singleEndUserUpdate(id, { ...data, status: isActive }, callback));
  };

  const onCreateSubmit = async (data) => {
    await http(() => {
      return createEndUserRequest(data);
    });
    setOpenState(true);
    navigate("/end-users");
  };

  useEffect(() => {
    isEdit && setIsActive(singleEndUser?.user?.status);
  }, [singleEndUser]);

  const onToggleChange = () => {
    setIsActive(!isActive);
  };

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    isEdit ? reset(singleEndUser.user) : reset();
  }, [singleEndUser]);

  const callbackRemove = () => {
    dispatch(fetchEndUsers());
    navigate("/end-users");
  };
  const removeSubmit = () => {
    dispatch(removeEndUser(id, callbackRemove));
  };
  function validatePhone(phone) {
    return !singleEndUser?.users?.some((user) => user.phone === phone);
  }

  function validateEmail(email) {
    return !singleEndUser?.users?.some((user) => user.email === email);
  }

  const showBackendErrors = () => {
    return (backendErrors || []).map((e) => (
      <li className="text-xs text-red-500 " key={e.code}>
        {e.message}
        <br />
      </li>
    ));
  };

  return (
    <div className="w-full h-full flex flex-col justify-around">
      {isEdit && (
        <PageHeader
          backLink={"/end-users"}
          Profile={
            singleEndUser?.user?.profilePicture &&
            singleEndUser?.user?.profilePicture.usePath ? (
              <img
                src={`${process.env.REACT_APP_API}${singleEndUser.user?.profilePicture?.usePath}/${singleEndUser?.user?.profilePicture?.filename}`}
                alt={singleEndUser?.user?.name}
                className="w-full object-cover h-full"
              />
            ) : (
              <FaceIcon className="text-white " />
            )
          }
          buttonIcon={<DeleteForeverIcon />}
          buttonTitle={"Remove this end user"}
          action={() => setRemoveModal(true)}
          buttonSecondary={true}
          title={
            singleEndUser?.user?.name
              ? singleEndUser?.user?.name
              : singleEndUser?.user?.phone
          }
          toggleChecked={isActive}
          onToggleChange={onToggleChange}
        />
      )}
      <div className="w-full h-full flex flex-col justify-around">
        <div className="py-2 align-middle inline-block min-w-full w-full">
          <form className="shadowBox overflow-hidden bg-white px-2 sm:px-6 lg:px-8 py-4 flex flex-col w-full sm:flex-row sm:justify-between flex-wrap">
            <div className="w-full flex items-center justify-center flex-col sm:flex-row ">
              <div className="w-full sm:w-1/2 px-2">
                <h3 className="text-lg flex itmes-start font-medium leading-6 text-gray-900">
                  Email
                </h3>
                <div
                  className={`w-full flex justify-center  items-center py-2 px-7  border border-fade rounded-4xl my-4`}
                >
                  <Input
                    id="support-email"
                    control={control}
                    fieldName="email"
                    disabled={isEdit && true}
                    type="email"
                    defaultValue={isEdit ? singleEndUser?.user?.email : ""}
                    placeholder={"Email"}
                    rules={{
                      pattern: {
                        value:
                          /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i,
                        message: "Please use a valid email address",
                      },
                      required: {
                        value: true,
                        message: "Please add the users email",
                      },
                      validate: {
                        checkEmail: (v) =>
                          validateEmail(v) || "Email already used",
                      },
                    }}
                  />
                </div>
                <p
                  className="text-xs text-red-500 "
                  style={{ minHeight: "1rem" }}
                ></p>
              </div>
              <div className="w-full sm:w-1/2 px-2">
                <h3 className="text-lg flex itmes-start font-medium leading-6 text-gray-900">
                  Name
                </h3>
                <div
                  className={`w-full flex justify-center  items-center py-2 px-7  border border-fade rounded-4xl my-4`}
                >
                  <Input
                    id="support-name"
                    control={control}
                    fieldName="name"
                    type="text"
                    defaultValue={isEdit ? singleEndUser?.user?.name : ""}
                    placeholder={"Name"}
                    rules={{
                      minLength: {
                        value: 4,
                        message: "The min length of the name is 4",
                      },
                      required: {
                        value: true,
                        message: "Name is required",
                      },
                    }}
                  />
                </div>
                <p
                  className="text-xs text-red-500 "
                  style={{ minHeight: "1rem" }}
                >
                  {errors.name && errors.name.message}
                </p>
              </div>
            </div>
            <div className="w-full sm:w-1/2 px-2">
              <h3 className="text-lg flex itmes-start font-medium leading-6 text-gray-900">
                Country
              </h3>
              <div
                className={`w-full flex justify-center  items-center py-1 px-7  border border-fade rounded-4xl my-4`}
              >
                <Controller
                  control={control}
                  render={({
                    field: { onChange, onBlur, value, name, ref },
                    fieldState: { invalid, isTouched, isDirty, error },
                    formState,
                  }) => {
                    return (
                      <CountriesAutocomplete
                        onChange={(country) => {
                          onChange(country?.code);
                        }}
                        defaultValue={
                          isEdit ? singleEndUser?.user?.country : ""
                        }
                      />
                    );
                  }}
                  name="country"
                  rules={{
                    required: {
                      value: true,
                      message: "Country is required",
                    },
                  }}
                />
              </div>
              <p
                className="text-xs text-red-500 "
                style={{ minHeight: "1rem" }}
              >
                {errors.country && errors.country.message}
              </p>
            </div>
            <div className="w-full sm:w-1/2 px-2">
              <h3 className="text-lg flex itmes-start font-medium leading-6 text-gray-900">
                Phone
              </h3>
              <div
                className={`w-full flex justify-center  items-center py-2 px-7  border border-fade rounded-4xl my-4`}
              >
                <Input
                  id="support-phone"
                  control={control}
                  fieldName="phone"
                  type="text"
                  defaultValue={isEdit ? singleEndUser?.user?.phone : ""}
                  placeholder={"+CC XXX XXX XXX"}
                  rules={{
                    pattern: {
                      value: /^\+[0-9]{3}-?[0-9]{6,13}$/,
                      message: "Please use a valid phone number",
                    },
                    required: {
                      value: true,
                      message: "Please add the users phone number",
                    },
                    validate: {
                      checkPhone: (v) =>
                        validatePhone(v) || "Phone number already used",
                    },
                  }}
                />
              </div>
              <p
                className="text-xs text-red-500 "
                style={{ minHeight: "1rem" }}
              >
                {errors.phone && errors.phone.message}
              </p>
            </div>
            {isEdit && (
              <div className="w-full">
                <hr className=" w-full pt-5 mt-5" />
                <h2 className="pb-4 text-lg flex justify-center font-large leading-6 text-gray-900">
                  Assign clients to {singleEndUser?.user?.name}
                </h2>
                <AssignClientsToEndUserList
                  endUser={singleEndUser.user}
                  setAssignedClients={setAssignedClients}
                />
              </div>
            )}
            <div className="w-full p-2">
              <MainButton
                action={handleSubmit(isEdit ? submit : onCreateSubmit)}
                title={isEdit ? "Save" : "Add end user"}
              />
            </div>{" "}
            <ul className="w-full flex flex-col justify-center items-center">
              {showBackendErrors()}
            </ul>
          </form>
          <div className="mt-4 shadowBox overflow-hidden overflow-x-auto bg-white  px-2 sm:px-6 lg:px-8 ">
            <div className="flex justify-center text-center p-2 font-bold text-xl">
              {" "}
              Related clients details
            </div>
            <table className="min-w-full divide-y divide-gray-200 ">
              <tbody className="bg-white divide-y divide-gray-200">
                {singleEndUser?.user?.connectedAUsers &&
                singleEndUser?.user?.connectedAUsers.length > 0 ? (
                  <>
                    {singleEndUser?.user?.connectedAUsers.map((item, index) => {
                      return (
                        <tr key={index}>
                          <td className="px-6 py-4 whitespace-nowrap">
                            <div className="text-sm flex flex-row font-medium text-gray-900 ml-4">
                              <div className="flex-shrink-0 h-10 w-10 bg-primary flex justify-center items-center rounded-full overflow-hidden">
                                {item.profilePicture &&
                                item.profilePicture.usePath ? (
                                  <img
                                    className="object-cover w-full h-full"
                                    src={`${process.env.REACT_APP_API}${item.profilePicture.usePath}/${item.profilePicture.filename}`}
                                  />
                                ) : (
                                  <FaceIcon className="text-white" />
                                )}
                              </div>
                              <div className="text-sm font-medium text-gray-900 ml-4">
                                {item.name}
                                <div className="text-sm text-gray-500">
                                  {item.phone} {item.email}
                                </div>
                              </div>
                            </div>
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap">
                            <div className=" text-sm text-gray-900 flex justify-center items-center">
                              connected to {item.connectedEndUsers.length} End
                              user
                              {""}
                              {item.connectedEndUsers.length !== 1 && (
                                <>{"s"}</>
                              )}
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                  </>
                ) : (
                  <tr>
                    <td>
                      <Empty info="This user has no clients connected" />
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>

        <div className="py-2 align-middle inline-block min-w-full">
          {isEdit && (
            <div className="shadowBox overflow-hidden  bg-white  px-2 sm:px-6 lg:px-8 py-4">
              <div className="text-textTitle flex items-center justify-center text-xl font-bold text">
                Models
              </div>
              {singleEndUser &&
              singleEndUser?.user?.models3D &&
              singleEndUser?.user?.models3D.length ? (
                <ModelsList userId={id} endUserModels={true} />
              ) : (
                <Empty
                  link="/models"
                  buttonTitle="Models"
                  info="Go to your models and Assign to this user"
                />
              )}
            </div>
          )}
          <RemoveModal
            visibility={removeModal}
            setVisibility={setRemoveModal}
            submitAction={removeSubmit}
            buttonText={"Remove user"}
            title="Are you sure you want to remove this user?"
            description={`This action is irreversible.`}
          />
          <SuccessModal
            visibility={showSuccessModal}
            setVisibility={setShowSuccessModal}
            title={"The update operation succeeded"}
          />
        </div>
      </div>
    </div>
  );
};

export default ViewEndOptions;
