import React, { useEffect, useState } from "react";
import ModelsListMarket from "../../Components/ui/pageElementes/ModelsListMarket";
import { useSelector, useDispatch } from "react-redux";
import { fetchMarketModels } from "../../redux/modules/models/actions";

import SearchField from "../../Components/ui/pageElementes/SearchField";
import FilterListTwoToneIcon from "@mui/icons-material/FilterListTwoTone";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MarketPlaceLayout from "../../Components/layouts/MarketplaceLayout";
const MarketPage = () => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.userData);
  const { models, categories } = useSelector((state) => state.modelData);
  const [filters, setFilters] = useState({});
  const [page, setPage] = useState(1);
  const [searchValue, setSearchValue] = useState("");
  const [sortBy, setSortBy] = useState("createdAt");
  const [sortValue, setSortValue] = useState("desc");
  const [anchorEl, setAnchorEl] = useState(null);
  const [showSidebar, setShowSidebar] = useState("-left-64");
  // const [minMax, setMinMax] = useState([0, 0]);
  useEffect(() => {
    dispatch(
      fetchMarketModels({
        page: 0,

        sortBy: sortBy,
        sortValue: sortValue,
      })
    );
  }, []);
  // useEffect(() => setMinMax(getMinMaxArea(models.models)), [models.models]);
  const search = (input, filters) => {
    setPage(1);
    dispatch(
      fetchMarketModels({
        sortBy: sortBy,
        sortValue: sortValue,
        search: input,
        filters: filters,
        page: 0,
      })
    );
  };
  const handlePage = (page) => {
    setPage(page);
    if (searchValue) {
      dispatch(
        fetchMarketModels({
          search: searchValue,
          page: page - 1,
          sortBy: sortBy,
          sortValue: sortValue,
        })
      );
    } else {
      dispatch(
        fetchMarketModels({
          page: page - 1,

          sortBy: sortBy,
          sortValue: sortValue,
        })
      );
    }
  };
  const handleSort = (by, value) => {
    setSortBy(by);
    setSortValue(value);
  };

  const clearFilters = () => {
    setFilters({ rooms: "", bathrooms: "", area: [1, 1000], category: "" });
  };
  return (
    <MarketPlaceLayout
      user={user}
      setFilters={setFilters}
      filters={filters}
      categories={categories}
      clearFilters={clearFilters}
      showSidebar={showSidebar}
    >
      {/* daca este logat un user o sa fie pus in state-ul 'user' */}
      <div
        className={`fixed top-0 lg:hidden ${
          showSidebar === "left-0"
            ? "left-64 w-full h-full"
            : "-left-64 bg-white"
        } z-50 transition-all duration-300 bg-gray-200 opacity-20 cursor-pointer`}
        onClick={() => setShowSidebar("-left-64")}
      ></div>
      <div className="w-full flex justify-between items-center flex-col sm:flex-row">
        <div className="w-3/4">
          <SearchField
            showFilterButton={false}
            action={search}
            setFilters={setFilters}
            filters={filters}
            searchValue={searchValue}
            setSearchValue={setSearchValue}
            sortBy={sortBy}
            sortValue={sortValue}
            width="sm:w-3/6"
          />
        </div>
        <div className="w-full sm:w-1/4 mx-4 flex justify-center  sm:justify-end  items-center">
          <div
            onClick={() => setShowSidebar("left-0")}
            className={`${
              showSidebar === "left-0" && "bg-primary text-white"
            } border-2 w-full rounded-full p-2 mx-4 mb-4 flex justify-center cursor-pointer lg:hidden`}
          >
            <FilterListTwoToneIcon />
            Filters
          </div>
          <div className=" rounded-full w-full p-2 mb-4 flex justify-center lg:justify-end cursor-pointer">
            {" "}
            <BasicMenu
              handleSort={handleSort}
              setAnchorEl={setAnchorEl}
              anchorEl={anchorEl}
            />
          </div>
        </div>
      </div>
      <ModelsListMarket
        user={user}
        fetchModels={true}
        isMarket={true}
        page={page}
        search={search}
        handlePage={handlePage}
        handleSort={handleSort}
        models={models}
      />
    </MarketPlaceLayout>
  );
};

export default MarketPage;
// const getMinMaxArea = (array) => {
//   const areas = [];
//   array.forEach((elem) => {
//     areas.push(elem.area);
//   });

//   const min = Math.min.apply(null, areas);
//   const max = Math.max.apply(null, areas);

//   return [min, max];
// };
export function BasicMenu({ anchorEl, setAnchorEl, handleSort }) {
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (sortBy, sortValue) => () => {
    handleSort(sortBy, sortValue);
    setAnchorEl(null);
  };

  return (
    <>
      <Button
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        Sort By
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={() => setAnchorEl(null)}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {" "}
        <MenuItem onClick={handleClose("createdAt", "desc")}>
          Newest first
        </MenuItem>{" "}
        <MenuItem onClick={handleClose("createdAt", "asc")}>
          Oldest first
        </MenuItem>
        <MenuItem onClick={handleClose("name", "asc")}>A - Z</MenuItem>
        <MenuItem onClick={handleClose("name", "desc")}>Z - A</MenuItem>
      </Menu>
    </>
  );
}
