export const ADMIN_PARTNER_TIER_TABLE_COLUMNS = [
  {
    field: "name",
    valueGetter: (params) => params.row?.name,
    headerName: "Name",
    flex: 1,
  },
  {
    field: "salesUpTo",
    valueGetter: (params) => params.row?.salesUpTo,
    headerName: "Sales Up To",
    flex: 1,
  },
  {
    field: "discountPercentage",
    valueGetter: (params) => params.row?.discountPercentage,
    headerName: "Discount Percentage",
    flex: 1,
  },
];
