import moment from "moment";

export const ADMIN_SUBSCRIPTION_PLANS_TABLE_COLUMNS = [
  { field: "name", headerName: "Name", flex: 1 },
  {
    field: "diskQuota",
    valueGetter: (params) => `${params.row.diskQuota} MB`,
    headerName: "Disk Quota",
    flex: 1,
  },
  {
    field: "monthly.price",
    valueGetter: (params) => `${params.row.monthly.price}$`,
    headerName: "Monthly Price",
    flex: 1,
  },
  {
    field: "yearly.price",
    valueGetter: (params) => `${params.row.yearly.price}$`,
    headerName: "Yearly Price",
    flex: 1,
  },
  {
    field: "updatedAt",
    valueGetter: (params) => moment(params.row.updatedAt).fromNow(),
    headerName: "Last Updated",
    flex: 1,
  },
];
