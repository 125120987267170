import MainButton from "../buttons/MainButton";
import Modal from "../pageElementes/Modal";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
const RemoveModal = ({
  setVisibility,
  visibility,
  title,
  description,
  submitAction,
  buttonText,
}) => {
  const hideModal = () => {
    setVisibility(false);
  };
  const submit = () => {
    submitAction();
    hideModal();
  };
  return (
    <Modal visibility={visibility} setVisibility={hideModal}>
      <div className="w-full flex flex-col justify-start items-center flex-1">
        <div className="w-full text-center flex flex-col justify-center items-center">
          <CancelOutlinedIcon className="text-red-500" sx={{ fontSize: 131 }} />
        </div>
        <h3>{title}</h3>
        <p className="flex  align-center text-center justify-center">
          {" "}
          {description}
        </p>
        <div className="w-full flex-col sm:flex-row flex justify-center items-center mt-4">
          <div className="w-full sm:w-1/2 px-2">
            <MainButton
              action={() => hideModal()}
              title="Cancel"
              extraClasses={"cursor-pointer"}
              width="w-full "
            />
          </div>
          <div className="w-full sm:w-1/2 p-2">
            <MainButton
              alert={true}
              action={() => submit()}
              title={buttonText || "Remove"}
              width="w-full"
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};
export default RemoveModal;
