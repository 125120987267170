import SearchIcon from "@mui/icons-material/Search";
import Modal from "../pageElementes/Modal";
import { useHttp } from "../../../hooks/useHttp";
import React, { useEffect, useState } from "react";
import LoadingIcon from "../icons/LoadingIcon";
import FaceIcon from "@mui/icons-material/Face";
import CheckBoxFull from "../primitives/CheckboxFull";
import CheckBox from "../primitives/Checkbox";
import { getEndUsersRequest } from "../../../api/modules/endUser";
import { getClientsRequest } from "../../../api/modules/clients";

const AssignUsersToModelModal = ({ model, onSubmit }) => {
  const { http } = useHttp();

  const [filteredUsers, setFilteredUsers] = useState([]);
  const [allUsers, setAllUsers] = useState([]);
  const [checkedUsers, setCheckedUsers] = useState([]);
  const [visibility, setVisibility] = useState(true);

  const [searchValue, setSearchValue] = useState("");
  const [modalIsLoading, setModalIsLoading] = useState(true);

  useEffect(() => {
    fetchAllUsers();
  }, []);

  useEffect(() => {
    prePopulateCheckedUsers();
    handleFilterActiveUsers();
  }, [allUsers]);

  useEffect(() => {
    handleFiltering();
  }, [searchValue]);

  const handleFilterActiveUsers = () => {
    const activeUsers = allUsers.filter((user) => user.status);
    setFilteredUsers(activeUsers);
  };

  const handleFiltering = () => {
    if (!filteredUsers) return;
    if (!searchValue.length) {
      setFilteredUsers(allUsers);
    }

    const _filteredUsers = (allUsers || []).filter((user) =>
      [user?.name, user?.phone, user?.email]
        .join(" ")
        .toLowerCase()
        .includes(searchValue.toLowerCase())
    );

    setFilteredUsers(_filteredUsers);
  };

  const fetchAllUsers = async () => {
    const [endUsers, users] = await Promise.all([
      http(() => getEndUsersRequest()),
      http(() => getClientsRequest()),
    ]);

    const _allUsers = [
      ...(endUsers || []).map((endUser) => ({ ...endUser, type: "EndUser" })),
      ...(users || []).map((endUser) => ({ ...endUser, type: "Client" })),
    ];

    const sortedUsers = sortUsersByCheckedStatus(_allUsers);
    setAllUsers(sortedUsers);
    setFilteredUsers(sortedUsers);

    setModalIsLoading(false);
  };

  const getUserRole = (user) => {
    return user?.role === "userA" ? "Client" : "End User";
  };

  const sortUsersByCheckedStatus = (users) => {
    const checkedTemp = (users || []).filter((item) =>
      item.models3D.includes(model.id)
    );

    return users.sort((a, b) => {
      const isCheckedA = !!checkedTemp.find((item) => item.id === a?.id);
      const isCheckedB = !!checkedTemp.find((item) => item.id === b?.id);
      return isCheckedA === isCheckedB ? 0 : isCheckedA ? -1 : 1;
    });
  };

  const prePopulateCheckedUsers = () => {
    const checked = (allUsers || []).filter((item) =>
      item.models3D.includes(model.id)
    );
    setCheckedUsers(checked);
  };

  const handleClickAssignments = (user, isChecked) => {
    if (!isChecked) {
      setCheckedUsers([...checkedUsers, user]);
      return;
    }

    const _checked = checkedUsers.filter((itemUser) => itemUser.id !== user.id);
    setCheckedUsers(_checked);
  };

  const handleSubmit = () => {
    const _checkedClients = checkedUsers.filter(
      (checkedUser) => checkedUser.type === "Client"
    );
    const _checkedEndUsers = checkedUsers.filter(
      (checkedUser) => checkedUser.type === "EndUser"
    );

    onSubmit({
      modelId: model.id,
      checkedEndUsersIds: _checkedEndUsers.map((row) => row.id),
      checkedClientsIds: _checkedClients.map((row) => row.id),
    });
  };

  return (
    <Modal
      title={`Assign model`}
      visibility={visibility}
      setVisibility={setVisibility}
      action={() => handleSubmit(model.id)}
    >
      <div
        className={`border-2 rounded-full w-full p-2 mb-4 flex`}
        style={{ minWidth: "400px" }}
      >
        <SearchIcon />
        <input
          placeholder="Type here to search..."
          type={"text"}
          value={searchValue}
          onChange={(e) => {
            setSearchValue(e.target.value);
          }}
          className={"focus:outline-none w-full ml-2"}
        />
      </div>

      {modalIsLoading ? (
        <LoadingIcon />
      ) : (
        (filteredUsers || []).map((item, i) => {
          const isChecked = !!checkedUsers.find(
            (checkedUser) => checkedUser.id === item?.id
          );
          const role = getUserRole(item);

          return (
            <div
              className={`w-full flex items-center my-2 p-2 relative cursor-pointer ${
                isChecked && "bg-primary bg-opacity-20"
              }`}
              key={i}
              onClick={() => handleClickAssignments(item, isChecked)}
            >
              <div className="flex-shrink-0 h-10 w-10 bg-primary flex justify-center items-center rounded-full">
                {item.profilePicture ? (
                  <img
                    src={`${process.env.REACT_APP_API}${item.profilePicture.usePath}/${item.profilePicture.filename}`}
                  />
                ) : (
                  <FaceIcon className="text-white" />
                )}
              </div>
              <div className="text-sm font-medium  text-gray-900 ml-4 pr-10">
                {item.name} ({role})
                <div className="text-sm mr-4 text-gray-500">
                  {item.phone} {item.email}
                </div>
              </div>
              {isChecked ? (
                <div className="absolute right-0">
                  <CheckBoxFull />
                </div>
              ) : (
                <div className="absolute right-0">
                  <CheckBox />
                </div>
              )}
            </div>
          );
        })
      )}
    </Modal>
  );
};
export default AssignUsersToModelModal;
