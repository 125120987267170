import React, { useEffect } from "react";
import { scroller } from "react-scroll";
import LandingPageLayout from "../../Components/layouts/LandingPageLayout";
import HomeSection from "../../Components/landingPage/home";
import HowItWorksSection from "../../Components/landingPage/how-it-works";
import AboutSection from "../../Components/landingPage/about";
// import FAQSection from "../../Components/landingPage/faq";
import ContactSection from "../../Components/landingPage/contact";

const LandingPage = () => {
  useEffect(() => {}, []);
  useEffect(() => {
    const target = window.location.href.split("#")[1];

    if (target !== undefined) {
      scroller.scrollTo(target, {
        duration: 500,
        smooth: "easeInOutQuint",
      });
    } else {
      window.scrollTo(0, 0);
    }
  }, []);
  return (
    <div className="w-full">
      <LandingPageLayout>
        <HomeSection />
        <HowItWorksSection />
        <AboutSection />
        {/* <FAQSection /> */}
        <ContactSection />
      </LandingPageLayout>
    </div>
  );
};

export default LandingPage;
