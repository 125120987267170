import MainButton from "../buttons/MainButton";
import Modal from "../pageElementes/Modal";
const ActivateUserModal = ({
  setVisibility,
  visibility,
  title,
  description,
  submitAction,
  buttonText,
}) => {
  const hideModal = () => {
    setVisibility(false);
  };
  return (
    <Modal visibility={visibility} setVisibility={hideModal}>
      <div className="w-full flex flex-col justify-start items-center flex-1">
        <h3>{title}</h3>
        <p className="flex  align-center text-center justify-center">
          {" "}
          {description}
        </p>
        <div className="w-full flex-col sm:flex-row flex justify-center items-center mt-4">
          <div className="w-full sm:w-1/2 px-2">
            <MainButton
              alert={false}
              action={() => hideModal()}
              title="Cancel"
              extraClasses={"cursor-pointer"}
              width="w-full "
            />
          </div>
          <div className="w-full sm:w-1/2 p-2 ">
            <MainButton
              success={true}
              action={submitAction}
              title={buttonText || "Activate"}
              width="w-full"
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};
export default ActivateUserModal;
