import axios from "axios";
import cookie from "js-cookie";

export const request = async (url, method, payload, headers) => {
  const DEFAULT_HEADERS = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${cookie.get("uac")}`,
  };

  try {
    const response = await axios.request({
      headers: { ...DEFAULT_HEADERS, ...headers },
      baseURL: process.env.REACT_APP_API,
      withCredentials: true,
      url,
      method,
      data: payload,
    });

    return response.data;
  } catch (e) {
    const response = e.response;

    if (!response) {
      console.log("API is down");
      return;
    }

    throw e.response;
  }
};
