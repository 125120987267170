import React, { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import PageHeader from "../../Components/ui/pageElementes/PageHeader";
import GroupIcon from "@mui/icons-material/Group";
import SquareButton from "../../Components/ui/buttons/SquareButton";
import RemoveModal from "../../Components/ui/modals/RemoveModal";
import { ADMIN_SUPPORT_TABLE_COLUMNS } from "./adminSupport.constants";
import { useHttp } from "../../hooks/useHttp";
import {
  deleteSupportUserRequest,
  getSupportUsersRequest,
} from "../../api/modules/supportUsers";
import ActivateUserModal from "../../Components/ui/modals/ActivateUserModal";
import { updateUserStatusRequest } from "../../api/modules/users";

const AdminSupportList = ({ data }) => {
  const navigate = useNavigate();
  const { http, isLoading } = useHttp();
  const [removeModal, setRemoveModal] = useState(false);
  const [selectedID, setSelectedID] = useState();
  const [supportUsers, setSupportUsers] = useState();
  const [showActivateUserModal, setShowActivateUserModal] = useState(false);

  const getSupportUsers = () => {
    http(() => {
      getSupportUsersRequest().then((res) => {
        return setSupportUsers(res);
      });
    });
  };

  useEffect(() => {
    getSupportUsers();
  }, [showActivateUserModal]);

  const handleActivateUser = () => {
    http(() => {
      updateUserStatusRequest(selectedID, { status: true });
    });
  };

  const removeSubmit = async () => {
    await http(() => {
      deleteSupportUserRequest(selectedID);
    });

    getSupportUsers();
  };

  const columns = [
    ...ADMIN_SUPPORT_TABLE_COLUMNS,
    {
      field: "actions",
      headerName: "Actions",
      sortable: false,
      filterable: false,
      headerAlign: "center",
      width: 200,
      renderCell: (params) => (
        <div
          key={params.id}
          style={{ flexDirection: "column", display: "flex", gap: "10px" }}
        >
          {!params.row.status && (
            <SquareButton
              padding={"px-7 py-1"}
              extraClasses={`text-xs`}
              action={() => {
                setSelectedID(params.id);
                setShowActivateUserModal(true);
              }}
              title="Activate User"
              fill={false}
            />
          )}
          <SquareButton
            action={() => {
              navigate(`/support/${params.id}`);
            }}
            extraClasses={"text-xs"}
            padding={"px-11 py-1"}
            title="Edit"
            fill={true}
          />
          <SquareButton
            padding={"px-11 py-1"}
            extraClasses={"text-xs"}
            action={() => {
              setSelectedID(params.id);
              setRemoveModal(true);
            }}
            title="Delete"
            fill={false}
          />
        </div>
      ),
    },
  ];

  return (
    <div className="w-full h-full flex flex-col justify-around">
      <div className="w-full h-full flex flex-col justify-around">
        <PageHeader
          Icon={GroupIcon}
          action={() => navigate("/support/new")}
          buttonTitle="Add Support User"
        />
        <div className="flex flex-col">
          <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
              <div className="shadowBox overflow-hidden ">
                <div style={{ width: "100%" }}>
                  <DataGrid
                    loading={isLoading}
                    style={{ border: "0", padding: "10px 20px" }}
                    rows={supportUsers || []}
                    columns={columns}
                    checkboxSelection={false}
                    pageSize={10}
                    autoHeight={true}
                    rowHeight={120}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <RemoveModal
          buttonText="Remove Support"
          visibility={removeModal}
          setVisibility={setRemoveModal}
          submitAction={removeSubmit}
          title="Are you sure you want to remove this support?"
          description={`This action is irreversible.`}
        />
        {showActivateUserModal && (
          <ActivateUserModal
            buttonText="Activate"
            visibility={showActivateUserModal}
            setVisibility={setShowActivateUserModal}
            submitAction={() => {
              handleActivateUser();
              setSelectedID(null);
              setShowActivateUserModal(false);
              getSupportUsers();
            }}
            title="Are you sure you want to activate this user?"
          />
        )}
      </div>
    </div>
  );
};

export default AdminSupportList;
