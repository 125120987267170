import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import logo from "../../assets/images/Logo.png";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import PersonIcon from "@mui/icons-material/Person";
import AddBusinessIcon from "@mui/icons-material/AddBusiness";
import AppRegistrationIcon from "@mui/icons-material/AppRegistration";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import ReceiptIcon from "@mui/icons-material/Receipt";
import PhoneIphoneIcon from "@mui/icons-material/PhoneIphone";
import ManIcon from "@mui/icons-material/Man";
import IconButton from "@mui/material/IconButton";
import FlagIcon from "@mui/icons-material/Flag";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { fetchUserData } from "../../redux/modules/user/actions";
import { useDispatch, useSelector } from "react-redux";
import { signup, clearError } from "../../redux/modules/auth/actions";
import Page from "../../Components/auth/Page";
import Container from "../../Components/auth/Container";
import MainButton from "../../Components/ui/buttons/MainButton";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import "../../pages/signupEndUser/style.css";
import { Controller, useForm } from "react-hook-form";
import CountriesAutocomplete from "../../Components/ui/forms/CountriesAutocomplete";
import SubscriptionPlansModal from "../../Components/stripe/SubscriptionPlansModal";

const SignupPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [showErrors, setShowErrors] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState();
  const [currency, setCurrency] = useState("");
  const [phone, setPhone] = useState("+40"); // TODO: detect automatically
  const [showSubscriptionModal, setShowSubscriptionModal] = useState(false);
  const [submitData, setSubmitData] = useState();
  const { user, isAuthenticated, error } = useSelector((state) => state.auth);

  const [values, setValues] = React.useState({
    email: "",
    password: "",
    name: "",
    phone: "",
    country: "",
    company: {
      name: "",
      registrationNumber: "",
      taxationNumber: "",
      address: "",
      representative: "",
    },
    showPassword: false,
  });

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleOnSubmit = (data) => {
    setSubmitData(data);
    setShowSubscriptionModal(true);
  };

  const submitSignup = (subscriptionPlan, interval, finishedCallback) => {
    const stripePriceID = subscriptionPlan[interval].stripePriceID;
    const subscriptionPlanId = subscriptionPlan.id;
    setIsLoading(true);
    dispatch(
      signup(
        {
          ...submitData,
          currency: currency,
          stripePriceID: stripePriceID,
          subscriptionPlanId: subscriptionPlanId,
        },
        (res) => {
          setIsLoading(false);
          if (res.paymentIntentClientSecret) {
            // There is a payment to be done. Open the modal
            finishedCallback(res.paymentIntentClientSecret);
          } else {
            // there is no payment to be done. Just do the redirect.
            dispatch(fetchUserData());
            navigate("/my-models");
          }
        },
        onError
      )
    );
  };

  const onError = () => {
    setShowSubscriptionModal(false);
  };

  useEffect(() => {
    if (isAuthenticated) {
      if (user.role === "Admin") {
        navigate("/models");
      } else if (user.role === "userA") {
        navigate("/my-models");
      }
    }
    dispatch(clearError());
  }, []);

  useEffect(() => {
    if (error && error.length > 0) {
      setShowErrors(true);
    }
  }, [error]);

  const errorDisplay = () => {
    if (error.length > 0) {
      return error.map((error, i) => {
        return (
          <p
            key={i}
            className="text-xs text-red-500 "
            style={{ minHeight: "1rem" }}
          >
            {error.message}
          </p>
        );
      });
    }
  };

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();

  return (
    <Page>
      <Container className="flex items-center w-full" style={{ width: "auto" }}>
        <Link to="/">
          <img src={logo} style={{ margin: "0 auto" }} className="logo h-32" />
        </Link>

        <div className="z-10 w-full bg-white sm:mt-5 px-5 sm:p-5 box-shadow-default rounded-lg flex justify-center items-center flex-col">
          <div className="py-7">
            <h2 className="font-bold text-center text-black py-2.5">Client</h2>
            <p className="font-medium text-center text-fade leading-7 tracking-wide">
              Sign up to XRETEH and continue
            </p>
          </div>
          <form className="form flex flex-col justify-between items-center w-full mx-10">
            <div className="lg:flex gap-12 w-full px-10">
              <div style={{ flex: "1 1 0" }}>
                <div className="w-full flex justify-center  items-center py-2 px-7  border border-fade rounded-4xl  mt-2">
                  <PersonIcon />

                  <Controller
                    control={control}
                    render={({
                      field: { onChange, onBlur, value, name, ref },
                      fieldState: { invalid, isTouched, isDirty, error },
                      formState,
                    }) => {
                      return (
                        <input
                          id="signup-name"
                          onChange={onChange}
                          placeholder="Name"
                          className="flex flex-1 appearance-none w-full  py-3 ml-5 leading-tight focus:outline-none"
                        />
                      );
                    }}
                    name="name"
                    rules={{
                      minLength: {
                        value: 4,
                        message: "The min length of the name is 4",
                      },
                      required: {
                        value: true,
                        message: "Your name is required",
                      },
                    }}
                  />
                </div>
                <p
                  className="text-xs text-red-500 "
                  style={{ minHeight: "1rem" }}
                >
                  {errors?.name?.message}
                </p>
                <div className="w-full flex justify-center  items-center py-2 px-7  border border-fade rounded-4xl mt-2">
                  <MailOutlineIcon />

                  <Controller
                    control={control}
                    render={({
                      field: { onChange, onBlur, value, name, ref },
                      fieldState: { invalid, isTouched, isDirty, error },
                      formState,
                    }) => {
                      return (
                        <input
                          id="signup-email"
                          onChange={onChange}
                          placeholder="Email"
                          className="flex flex-1 appearance-none w-full py-3 ml-5 leading-tight focus:outline-none"
                        />
                      );
                    }}
                    name="email"
                    rules={{
                      pattern: {
                        value:
                          /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i,
                        message: "Please use a valid email address",
                      },
                      required: {
                        value: true,
                        message: "Please add an email address",
                      },
                    }}
                  />
                </div>
                <p
                  className="text-xs text-red-500 "
                  style={{ minHeight: "1rem" }}
                >
                  {errors.email && errors.email.message}
                </p>
                <div className="w-full flex justify-center  items-center py-2 px-7  border border-fade rounded-4xl mt-2">
                  <LockOutlinedIcon />
                  <Controller
                    control={control}
                    render={({
                      field: { onChange, onBlur, value, name, ref },
                      fieldState: { invalid, isTouched, isDirty, error },
                      formState,
                    }) => {
                      return (
                        <>
                          <input
                            id="signup-password"
                            type={values.showPassword ? "text" : "password"}
                            placeholder="Password"
                            onChange={onChange}
                            className="flex flex-1 appearance-none w-full py-3 ml-5 leading-tight focus:outline-none"
                          />
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                            >
                              {values.showPassword ? (
                                <VisibilityOff />
                              ) : (
                                <Visibility />
                              )}
                            </IconButton>
                          </InputAdornment>
                        </>
                      );
                    }}
                    name="password"
                    rules={{
                      minLength: {
                        value: 4,
                        message: "The min length of the name is 4",
                      },
                      required: {
                        value: true,
                        message: "The password is required",
                      },
                    }}
                  />
                </div>
                <p
                  className="text-xs text-red-500 "
                  style={{ minHeight: "1rem" }}
                >
                  {errors.password && errors.password.message}
                </p>
                <div className="w-full flex justify-center  items-center py-2 px-7  border border-fade rounded-4xl  mt-2">
                  <FlagIcon />
                  <Controller
                    control={control}
                    render={({
                      field: { onChange, onBlur, value, name, ref },
                      fieldState: { invalid, isTouched, isDirty, error },
                      formState,
                    }) => {
                      return (
                        <CountriesAutocomplete
                          onChange={(country) => {
                            onChange(country?.code);
                            setSelectedCountry(country?.code.toLowerCase());
                            setCurrency(country?.isEEA ? "eur" : "usd");
                          }}
                          defaultValue={"RO"}
                          extraClasses={"py-1.5"}
                        />
                      );
                    }}
                    name="country"
                    rules={{
                      required: {
                        value: true,
                        message: "Country is required",
                      },
                    }}
                  />
                </div>
                <p
                  className="text-xs text-red-500 "
                  style={{ minHeight: "1rem" }}
                >
                  {errors?.country?.message}
                </p>

                <div className="w-full flex justify-center  items-center py-2 px-7  border border-fade rounded-4xl mt-2">
                  <PhoneIphoneIcon />

                  <Controller
                    control={control}
                    render={({
                      field: { onChange, onBlur, value, name, ref },
                      fieldState: { invalid, isTouched, isDirty, error },
                      formState,
                    }) => {
                      return (
                        <PhoneInput
                          country={selectedCountry}
                          inputClass="phone-input"
                          buttonClass="phone-dropdown"
                          enableSearch={true}
                          countryCodeEditable={false}
                          value={phone}
                          onChange={(phone) => {
                            setPhone(phone);
                            onChange("+" + phone);
                          }}
                        />
                      );
                    }}
                    name="phone"
                    rules={{
                      pattern: {
                        value: /^\+[0-9]{3}-?[0-9]{6,13}$/,
                        message: "Please use a valid phone number",
                      },
                      required: {
                        value: true,
                        message: "Please add a phone number",
                      },
                    }}
                  />
                </div>
                <p
                  className="text-xs text-red-500 "
                  style={{ minHeight: "1rem" }}
                >
                  {errors.phone && errors.phone.message}
                </p>
              </div>
              <div style={{ flex: "1 1 0" }}>
                <div className="w-full flex justify-center  items-center py-2 px-7  border border-fade rounded-4xl  mt-2">
                  <AddBusinessIcon />

                  <Controller
                    control={control}
                    render={({
                      field: { onChange, onBlur, value, name, ref },
                      fieldState: { invalid, isTouched, isDirty, error },
                      formState,
                    }) => {
                      return (
                        <input
                          id="signup-companyName"
                          onChange={onChange}
                          placeholder="Company Name"
                          className="flex flex-1 appearance-none w-full py-3 ml-5 leading-tight focus:outline-none"
                        />
                      );
                    }}
                    name="company.name"
                    rules={{
                      minLength: {
                        value: 4,
                        message: "The min length of the company name is 4",
                      },
                      required: {
                        value: true,
                        message: "Company name is required",
                      },
                    }}
                  />
                </div>
                <p
                  className="text-xs text-red-500 "
                  style={{ minHeight: "1rem" }}
                >
                  {errors?.company?.name?.message}
                </p>
                <div className="w-full flex justify-center  items-center py-2 px-7  border border-fade rounded-4xl  mt-2">
                  <AppRegistrationIcon />

                  <Controller
                    control={control}
                    render={({
                      field: { onChange, onBlur, value, name, ref },
                      fieldState: { invalid, isTouched, isDirty, error },
                      formState,
                    }) => {
                      return (
                        <input
                          id="signup-companyRegistrationNumber"
                          onChange={onChange}
                          placeholder="Company Registration Number"
                          className="flex flex-1 appearance-none w-full py-3 ml-5 leading-tight focus:outline-none"
                        />
                      );
                    }}
                    name="company.registrationNumber"
                    rules={{
                      rules: {
                        maxLength: 30,
                        message: "Please use a valid registration number",
                      },
                      required: {
                        value: true,
                        message: "Company registration number is required",
                      },
                    }}
                  />
                </div>
                <p
                  className="text-xs text-red-500 "
                  style={{ minHeight: "1rem" }}
                >
                  {errors?.company?.registrationNumber?.message}
                </p>
                <div className="w-full flex justify-center  items-center py-2 px-7  border border-fade rounded-4xl  mt-2">
                  <ReceiptIcon />

                  <Controller
                    control={control}
                    render={({
                      field: { onChange, onBlur, value, name, ref },
                      fieldState: { invalid, isTouched, isDirty, error },
                      formState,
                    }) => {
                      return (
                        <input
                          id="signup-companyTaxationNumber"
                          onChange={onChange}
                          placeholder="Company Taxation Number"
                          className="flex flex-1 appearance-none w-full py-3 ml-5 leading-tight focus:outline-none"
                        />
                      );
                    }}
                    name="company.taxationNumber"
                    rules={{
                      rules: {
                        maxLength: 30,
                        message: "Please use a valid taxation number",
                      },
                      required: {
                        value: true,
                        message: "Company taxation number is required",
                      },
                    }}
                  />
                </div>
                <p
                  className="text-xs text-red-500 "
                  style={{ minHeight: "1rem" }}
                >
                  {errors?.company?.taxationNumber?.message}
                </p>
                <div className="w-full flex justify-center  items-center py-2 px-7  border border-fade rounded-4xl  mt-2">
                  <LocationOnIcon />

                  <Controller
                    control={control}
                    render={({
                      field: { onChange, onBlur, value, name, ref },
                      fieldState: { invalid, isTouched, isDirty, error },
                      formState,
                    }) => {
                      return (
                        <input
                          id="signup-companyAddress"
                          onChange={onChange}
                          placeholder="Company Address"
                          className="flex flex-1 appearance-none w-full py-3 ml-5 leading-tight focus:outline-none"
                        />
                      );
                    }}
                    name="company.address"
                    rules={{
                      minLength: {
                        value: 4,
                        message: "The min lenght of company address must be 4",
                      },
                      required: {
                        value: true,
                        message: "Company address is required",
                      },
                    }}
                  />
                </div>
                <p
                  className="text-xs text-red-500 "
                  style={{ minHeight: "1rem" }}
                >
                  {errors?.company?.address?.message}
                </p>
                <div className="w-full flex justify-center  items-center py-2 px-7  border border-fade rounded-4xl  mt-2">
                  <ManIcon />

                  <Controller
                    control={control}
                    render={({
                      field: { onChange, onBlur, value, name, ref },
                      fieldState: { invalid, isTouched, isDirty, error },
                      formState,
                    }) => {
                      return (
                        <input
                          id="signup-companyRepresentative"
                          onChange={onChange}
                          placeholder="Company Representative"
                          className="flex flex-1 appearance-none w-full py-3 ml-5 leading-tight focus:outline-none"
                        />
                      );
                    }}
                    name="company.representative"
                    rules={{
                      minLength: {
                        value: 4,
                        message:
                          "The min lenght of company representative must be 4",
                      },
                      required: {
                        value: true,
                        message: "Company representative is required",
                      },
                    }}
                  />
                </div>
                <p
                  className="text-xs text-red-500 "
                  style={{ minHeight: "1rem" }}
                >
                  {errors?.company?.representative?.message}
                </p>
              </div>
            </div>

            <div>
              <div className="w-full flex justify-evenly my-2">
                <Link
                  className="text-primary font-medium text-center sm:text-right"
                  to="/login"
                >
                  Already have an account?
                </Link>
              </div>
              {showErrors && error.length > 0 && errorDisplay()}
              <div className="flex justify-center w-full my-2">
                <MainButton
                  title="Enter the virtual world"
                  action={handleSubmit(handleOnSubmit)}
                  width="w-full"
                />
              </div>
            </div>
          </form>

          {showSubscriptionModal && (
            <SubscriptionPlansModal
              visibility={showSubscriptionModal}
              setVisibility={setShowSubscriptionModal}
              submitAction={submitSignup}
              isLoading={isLoading}
              title={"Choose subscription"}
            />
          )}
        </div>
      </Container>
    </Page>
  );
};

export default SignupPage;
