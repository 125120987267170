import React, { useState, useEffect } from "react";
import FaceIcon from "@mui/icons-material/Face";
import ModelsList from "../../Components/ui/pageElementes/ModelsList";
import {
  fetchSingleUser,
  singleUserUpdate,
  removeAUser,
  fetchUsers,
  fetchLatestUsers,
  addArchitectUser,
} from "../../redux/modules/admin/actions";
import { useSelector, useDispatch } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import { ControlledInput as Input } from "../../Components/ui/forms/ControlledInput";
import MainButton from "../../Components/ui/buttons/MainButton";
import { useForm, Controller } from "react-hook-form";
import Empty from "../../Components/ui/pageElementes/Empty";
import PageHeader from "../../Components/ui/pageElementes/PageHeader";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import RemoveModal from "../../Components/ui/modals/RemoveModal";
import SuccessModal from "../../Components/ui/modals/SuccessModal";
import { breakConnection } from "../../redux/modules/clients/actions";
import { useModalContext } from "../../SuccessModalContext";
import CountriesAutocomplete from "../../Components/ui/forms/CountriesAutocomplete";
import { useHttp } from "../../hooks/useHttp";
import {
  assignEndUsersToClientRequest,
  assignPartnerToClientRequest,
} from "../../api/modules/clients";
import AssignEndUsersList from "./assignEndUsers.list";
import AssignPartnerList from "./assignPartner.list";

const ViewClientOptions = (props) => {
  const { id } = useParams();
  const isEdit = id !== undefined;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { singleUser, endUsers, error } = useSelector(
    (state) => state.adminData
  );
  const { http } = useHttp();
  const { setOpenState } = useModalContext();
  const [removeModal, setRemoveModal] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [currentDeletedUser, setCurrentDeletedUser] = useState(null);
  const [assignedEndUsers, setAssignedEndUsers] = useState([]);
  const [assignedPartner, setAssignedPartner] = useState("");
  const [currency, setCurrency] = useState("");
  const [isActive, setIsActive] = useState(true);

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    window.scrollTo(0, 0);

    isEdit && dispatch(fetchSingleUser(id));
  }, []);

  const [values, setValues] = useState({
    name: "",
    phone: "",
    company: {
      name: "",
      address: "",
      representative: "",
      registrationNumber: "",
      taxationNumber: "",
    },
    subscribed: "",
  });

  useEffect(() => {
    setValues({
      name: singleUser?.user?.name,
      phone: singleUser?.user?.phone,
      country: singleUser?.user?.country,
      company: {
        name: singleUser?.user?.company?.name,
        address: singleUser?.user?.company?.address,
        representative: singleUser?.user?.company?.representative,
        registrationNumber: singleUser?.user?.company?.registrationNumber,
        taxationNumber: singleUser?.user?.company?.taxationNumber,
      },
      subscribed: singleUser?.user?.subscribed,
    });
    setIsActive(singleUser?.user?.status);
  }, [singleUser]);

  const callback = () => {
    dispatch(fetchSingleUser(id));
    dispatch(fetchLatestUsers());
    setOpenState(true);
    navigate("/clients");
  };

  const closeRemoveUserModal = () => {
    setCurrentDeletedUser(null);
  };

  const submit = async (data) => {
    await http(() => {
      return Promise.all([
        assignEndUsersToClientRequest({ id: id, endUserIds: assignedEndUsers }),
        assignPartnerToClientRequest({
          clientId: id,
          partnerId: assignedPartner,
        }),
      ]);
    });

    dispatch(singleUserUpdate(id, { ...data, status: isActive }, callback));
  };

  useEffect(() => {
    setAssignedEndUsers([]);
    const checkedUserIds = singleUser?.user?.connectedEndUsers.map(
      (item) => item.id
    );
    setAssignedEndUsers(checkedUserIds);
  }, [singleUser, endUsers]);

  const addClientCallback = () => {
    dispatch(fetchUsers());
    navigate("/clients");
  };
  const onCreateSubmit = (data) => {
    dispatch(
      addArchitectUser({ ...data, currency: currency }, addClientCallback)
    );
  };

  useEffect(() => {
    isEdit ? reset(singleUser?.user) : reset();
  }, [singleUser]);

  const callbackRemove = () => {
    dispatch(fetchUsers());
    if (singleUser?.user?.role === "Partner") {
      navigate("/partners");
    } else {
      navigate("/clients");
    }
  };
  const removeSubmit = () => {
    dispatch(removeAUser(id, callbackRemove));
  };
  const removeUserSubmit = () => {
    dispatch(
      breakConnection(
        { endUser: currentDeletedUser.id, currentUser: id },
        callBackRemoveUser
      )
    );
    callBackRemoveUser();
  };
  const onToggleChange = () => {
    setIsActive(!isActive);
  };

  const callBackRemoveUser = () => {
    dispatch(fetchSingleUser(id));
    closeRemoveUserModal();
  };
  function validatePhone(phone) {
    return !singleUser?.users?.some((user) => user.phone === phone);
  }

  function validateEmail(email) {
    return !singleUser?.users?.some((user) => user.email === email);
  }
  const backendErrors = () => {
    return error?.map((e) => (
      <li className="text-xs text-red-500 " key={e.code}>
        {e.message}
        <br />
      </li>
    ));
  };
  return (
    <div className="w-full h-full flex flex-col justify-around">
      {isEdit && (
        <PageHeader
          backLink={"/clients"}
          Profile={
            singleUser?.user?.profilePicture ? (
              <img
                src={`${process.env.REACT_APP_API}${singleUser.user?.profilePicture.usePath}/${singleUser.user?.profilePicture.filename}`}
                alt={singleUser?.user?.name}
                className="w-full object-cover h-full"
              />
            ) : (
              <FaceIcon className="text-white " />
            )
          }
          buttonIcon={<DeleteForeverIcon />}
          buttonTitle="Remove this client"
          action={() => setRemoveModal(true)}
          buttonSecondary={true}
          title={
            singleUser?.user?.name
              ? singleUser?.user?.name
              : singleUser?.user?.email
          }
          toggleChecked={isActive}
          onToggleChange={onToggleChange}
        />
      )}
      <div className="py-2 align-middle inline-block min-w-full w-full">
        <form className="shadowBox overflow-hidden bg-white px-2 sm:px-6 lg:px-8 py-4 flex flex-col w-full sm:flex-row sm:justify-between flex-wrap">
          <div className="w-full flex items-center justify-center flex-col sm:flex-row ">
            <div className="w-full sm:w-1/2 px-2">
              <h3 className="text-lg flex itmes-start font-medium leading-6 text-gray-900">
                Email
              </h3>
              <div
                className={`w-full flex justify-center  items-center py-2 px-7  border border-fade rounded-4xl my-4`}
              >
                <Input
                  id="client-email"
                  control={control}
                  fieldName="email"
                  disabled={isEdit && true}
                  type="email"
                  defaultValue={isEdit ? singleUser?.user?.email : ""}
                  placeholder={"Email"}
                  rules={{
                    pattern: {
                      value:
                        /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i,
                      message: "Please use a valid email address",
                    },
                    required: {
                      value: true,
                      message: "Please add the users email",
                    },
                    validate: {
                      checkEmail: (v) =>
                        validateEmail(v) || "Email already used",
                    },
                  }}
                />
              </div>
              <p
                className="text-xs text-red-500 "
                style={{ minHeight: "1rem" }}
              />
            </div>
            <div className="w-full sm:w-1/2 px-2">
              <h3 className="text-lg flex itmes-start font-medium leading-6 text-gray-900">
                Name
              </h3>
              <div
                className={`w-full flex justify-center  items-center py-2 px-7  border border-fade rounded-4xl my-4`}
              >
                <Input
                  id="client-name"
                  control={control}
                  fieldName="name"
                  type="text"
                  defaultValue={isEdit ? singleUser?.user?.name : ""}
                  placeholder={"Name"}
                  rules={{
                    minLength: {
                      value: 4,
                      message: "The min length of the name is 4",
                    },
                  }}
                />
              </div>
              <p
                className="text-xs text-red-500 "
                style={{ minHeight: "1rem" }}
              >
                {errors.name && errors.name.message}
              </p>
            </div>
          </div>
          <div className="w-full sm:w-1/2 px-2">
            <h3 className="text-lg flex itmes-start font-medium leading-6 text-gray-900">
              Phone
            </h3>
            <div
              className={`w-full flex justify-center  items-center py-2 px-7  border border-fade rounded-4xl my-4`}
            >
              <Input
                id="client-phone"
                control={control}
                fieldName="phone"
                type="text"
                defaultValue={isEdit ? singleUser?.user?.phone : ""}
                placeholder={"+CC XXX XXX XXX"}
                rules={{
                  pattern: {
                    value: /^\+[0-9]{3}-?[0-9]{6,13}$/,
                    message: "Please use a valid phone number",
                  },
                  required: {
                    value: true,
                    message: "Please add the users phone number",
                  },
                  validate: {
                    checkPhone: (v) =>
                      validatePhone(v) || "Phone number already used",
                  },
                }}
              />
            </div>

            <p className="text-xs text-red-500 " style={{ minHeight: "1rem" }}>
              {errors.phone && errors.phone.message}
            </p>
          </div>
          <div className="w-full sm:w-1/2 px-2">
            <h3 className="text-lg flex items-start font-medium leading-6 text-gray-900">
              Country
            </h3>
            <div
              className={`w-full flex justify-center  items-center  px-7  border border-fade rounded-4xl my-4`}
            >
              <Controller
                control={control}
                render={({
                  field: { onChange, onBlur, value, name, ref },
                  fieldState: { invalid, isTouched, isDirty, error },
                  formState,
                }) => {
                  return (
                    <CountriesAutocomplete
                      onChange={(country) => {
                        onChange(country?.code);
                        setValues({ ...values, country: country?.code });
                        setCurrency(country?.isEEA ? "eur" : "usd");
                      }}
                      defaultValue={isEdit ? singleUser?.user?.country : ""}
                    />
                  );
                }}
                name="country"
                rules={{
                  required: {
                    value: true,
                    message: "Country is required",
                  },
                }}
              />
            </div>
            <p className="text-xs text-red-500 " style={{ minHeight: "1rem" }}>
              {errors.country && errors.country.message}
            </p>
          </div>
          <div className="w-full">
            <h2 className="text-gray-900 text-xl leading-tight font-medium mb-2">
              Company
            </h2>
            <hr className="pt-5 mt-5" />
          </div>
          <div className="w-full sm:w-1/3 px-2">
            <h3 className="text-lg flex itmes-start font-medium leading-6 text-gray-900">
              Name
            </h3>
            <div
              className={`w-full flex justify-center  items-center py-2 px-7  border border-fade rounded-4xl my-4`}
            >
              <Input
                id="client-company-name"
                control={control}
                fieldName="company.name"
                type="text"
                defaultValue={isEdit ? singleUser?.user?.company?.name : ""}
                placeholder={"Company"}
                rules={{
                  minLength: {
                    value: 3,
                    message: "The min length of the company name is 3",
                  },
                }}
              />
            </div>
            <p className="text-xs text-red-500 " style={{ minHeight: "1rem" }}>
              {errors?.company?.name?.message}
            </p>
          </div>
          <div className="w-full sm:w-1/3 px-2">
            <h3 className="text-lg flex itmes-start font-medium leading-6 text-gray-900">
              Address
            </h3>
            <div
              className={`w-full flex justify-center  items-center py-2 px-7  border border-fade rounded-4xl my-4`}
            >
              <Input
                id="client-company-address"
                control={control}
                fieldName="company.address"
                type="text"
                defaultValue={isEdit ? singleUser?.user?.company?.name : ""}
                placeholder={"Company address"}
                rules={{
                  minLength: {
                    value: 3,
                    message: "The min length of the company address is 3",
                  },
                }}
              />
            </div>
            <p className="text-xs text-red-500 " style={{ minHeight: "1rem" }}>
              {errors?.company?.address?.message}
            </p>
          </div>
          <div className="w-full sm:w-1/3 px-2">
            <h3 className="text-lg flex itmes-start font-medium leading-6 text-gray-900">
              Representative
            </h3>
            <div
              className={`w-full flex justify-center  items-center py-2 px-7  border border-fade rounded-4xl my-4`}
            >
              <Input
                id="representative"
                control={control}
                fieldName="company.representative"
                type="text"
                defaultValue={
                  isEdit ? singleUser?.user?.company?.representative : ""
                }
                placeholder={"Name"}
                rules={{
                  minLength: {
                    value: 3,
                    message: "The min length of the representative name is 3",
                  },
                }}
              />
            </div>
            <p className="text-xs text-red-500 " style={{ minHeight: "1rem" }}>
              {errors?.company?.representative?.message}
            </p>
          </div>
          <div className="w-full sm:w-1/2 px-2">
            <h3 className="text-lg flex itmes-start font-medium leading-6 text-gray-900">
              Registration number
            </h3>
            <div
              className={`w-full flex justify-center  items-center py-2 px-7  border border-fade rounded-4xl my-4`}
            >
              <Input
                id="client-company-registration-number"
                control={control}
                fieldName="company.registrationNumber"
                type="text"
                defaultValue={
                  isEdit ? singleUser?.user?.company?.registrationNumber : ""
                }
                placeholder={"Registration number"}
                rules={{
                  minLength: {
                    value: 3,
                    message:
                      "The min length of the company registration number is 3",
                  },
                  maxLength: {
                    value: 30,
                    message:
                      "The max length of the company registration number is 30",
                  },
                }}
              />
            </div>
            <p className="text-xs text-red-500 " style={{ minHeight: "1rem" }}>
              {errors?.company?.registrationNumber?.message}
            </p>
          </div>
          <div className="w-full sm:w-1/2 px-2">
            <h3 className="text-lg flex itmes-start font-medium leading-6 text-gray-900">
              Taxation number
            </h3>
            <div
              className={`w-full flex justify-center  items-center py-2 px-7  border border-fade rounded-4xl my-4`}
            >
              <Input
                id="client-company-taxation-number"
                control={control}
                fieldName="company.taxationNumber"
                type="text"
                defaultValue={
                  isEdit ? singleUser?.user?.company?.taxationNumber : ""
                }
                placeholder={"Taxation number"}
                rules={{
                  minLength: {
                    value: 3,
                    message:
                      "The min length of the company taxation number is 3",
                  },
                  maxLength: {
                    value: 30,
                    message:
                      "The max length of the company taxation number is 30",
                  },
                }}
              />
            </div>
            <p className="text-xs text-red-500 " style={{ minHeight: "1rem" }}>
              {errors?.company?.taxationNumber?.message}
            </p>
          </div>
          <hr className=" w-full pt-5 mt-5" />
          {isEdit && (
            <div className="w-full ">
              <hr className=" w-full pt-5 mt-5" />
              <h2 className="pb-4 text-lg flex justify-center font-large leading-6 text-gray-900">
                Assign end users to {singleUser?.user?.name}
              </h2>
              <AssignEndUsersList
                client={singleUser.user}
                setAssignedEndUsers={setAssignedEndUsers}
              />
              <hr className=" w-full pt-5 mt-5" />
              <h2 className="pb-4 text-lg flex justify-center font-large leading-6 text-gray-900">
                Assign a partner to {singleUser?.user?.name}
              </h2>
              <AssignPartnerList
                client={singleUser.user}
                setAssignedPartner={setAssignedPartner}
              />
            </div>
          )}
          <div className="w-full p-2">
            <MainButton
              action={handleSubmit(isEdit ? submit : onCreateSubmit)}
              title={isEdit ? "Save" : "Add client"}
            />
          </div>{" "}
          <ul className="w-full flex flex-col justify-center items-center">
            {backendErrors()}
          </ul>
        </form>
      </div>

      <div className="shadowBox overflow-hidden overflow-x-auto bg-white  px-2 sm:px-6 lg:px-8 ">
        <div className="flex justify-center text-center p-2 font-bold text-xl">
          {" "}
          Related end users details
        </div>
        <table className="min-w-full divide-y divide-gray-200 ">
          <tbody className="bg-white divide-y divide-gray-200">
            {singleUser?.user?.connectedEndUsers &&
            singleUser?.user?.connectedEndUsers.length > 0 ? (
              singleUser?.user?.connectedEndUsers.map((item, i) => {
                return (
                  <tr key={i}>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="flex-shrink-0 h-10 w-10 bg-primary flex justify-center items-center rounded-full overflow-hidden">
                        {item.profilePicture && item.profilePicture.usePath ? (
                          <img
                            className="object-cover w-full h-full"
                            src={`${process.env.REACT_APP_API}${item.profilePicture.usePath}/${item.profilePicture.filename}`}
                          />
                        ) : (
                          <FaceIcon className="text-white" />
                        )}
                      </div>{" "}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="text-sm font-medium text-gray-900 ml-4">
                        {item.name}
                        <div className="text-sm text-gray-500">
                          {item.phone} {item.email}
                        </div>
                      </div>{" "}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className=" text-sm text-gray-900">
                        assigned to {item.connectedAUsers.length} client
                        {""}
                        {item.connectedAUsers.length !== 1 && <>{"s"}</>}
                      </div>{" "}
                    </td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td>
                  <Empty info="This user has no clients connected" />
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>

      <div className="py-2 align-middle inline-block min-w-full">
        <div className="shadowBox overflow-hidden bg-white  px-2 sm:px-6 lg:px-8">
          <div className="flex justify-center text-center p-2 font-bold text-xl">
            {" "}
            Models
          </div>
          {singleUser && singleUser.models && singleUser.models.length ? (
            <ModelsList extraLink="/models" userId={id} modalShow={() => {}} />
          ) : (
            <Empty info="This user has no 3D models" />
          )}
        </div>
        <RemoveModal
          visibility={removeModal}
          setVisibility={setRemoveModal}
          submitAction={removeSubmit}
          title="Are you sure you want to remove this user?"
          description={`This action is irreversible.`}
          buttonText="Remove architect"
        />

        <RemoveModal
          buttonText="Remove user"
          visibility={currentDeletedUser !== null}
          setVisibility={closeRemoveUserModal}
          submitAction={removeUserSubmit}
          title="Are you sure you want to remove this user?"
          description={currentDeletedUser && `This action is irreversible.`}
        />
        <SuccessModal
          visibility={showSuccessModal}
          setVisibility={setShowSuccessModal}
          title={"The update operation succeeded"}
        />
      </div>
    </div>
  );
};

export default ViewClientOptions;
