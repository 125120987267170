import React, { useState } from "react";
import {
  errorClear,
  assignModelToUsers,
  assignModelToEndUsers,
} from "../../../redux/modules/models/actions";
import {
  fetchModels as fetchModelsAdmin,
  fetchEndUsers,
  searchModels,
  searchEndUsers,
} from "../../../redux/modules/admin/actions";
import { useSelector, useDispatch } from "react-redux";
import "../../../index.css";
import SearchField from "../pageElementes/SearchField";
import Pagination from "./Pagination";

import ListElement from "./ListElement";
import AssignUsersToModelModal from "../modals/AssignUsersToModelModal";

const ModelsListAdmin = (props) => {
  const [page, setPage] = useState(1);
  const [searchValue, setSearchValue] = useState("");
  const { models } = useSelector((state) => state.adminData);
  const dispatch = useDispatch();

  const [showModal, setShowModal] = useState(false);
  const [selectedModel, setSelectedModel] = useState();

  const [modalSearchValue, setModalSearchValue] = useState("");

  const [filters, setFilters] = useState({});

  React.useEffect(() => {
    dispatch(errorClear());
    dispatch(fetchModelsAdmin());
  }, []);

  const search = (input, filters) => {
    setPage(1);
    dispatch(searchModels(input, filters, 0));
  };

  const handlePage = (page) => {
    setPage(page);
    if (searchValue) {
      dispatch(searchModels(searchValue, filters, page - 1));
    } else {
      dispatch(fetchModelsAdmin(page - 1));
    }
  };

  const onModalSubmit = ({
    modelId,
    checkedEndUsersIds,
    checkedClientsIds,
  }) => {
    dispatch(
      assignModelToEndUsers({
        modelId: modelId,
        endUserIds: checkedEndUsersIds,
      })
    );
    dispatch(
      assignModelToUsers({ modelId: modelId, userIds: checkedClientsIds })
    );

    setShowModal(false);
  };

  const modalSearch = (input, filters) => {
    setModalSearchValue(input);
    if (input === "" || input === undefined) {
      dispatch(fetchEndUsers(0, props.totalClients));
    } else {
      dispatch(searchEndUsers(input, 0));
    }
  };

  return (
    <>
      <SearchField
        action={search}
        showFilterButton={true}
        setFilters={setFilters}
        filters={filters}
        searchValue={searchValue}
        setSearchValue={setSearchValue}
      />

      <div className="w-full flex flex-wrap justify-center lg:justify-start items-center">
        {(models?.models || []).map((item, i) => {
          return (
            <ListElement
              key={i}
              model={item}
              index={i}
              showModal={showModal}
              searchValue={modalSearchValue}
              setShowModal={setShowModal}
              setSearchValue={setModalSearchValue}
              search={modalSearch}
              setSelectedModel={setSelectedModel}
            />
          );
        })}
      </div>
      <Pagination
        pageSize={models.pageSize}
        totalPages={models.totalPages}
        total={models.total}
        page={page}
        setPage={handlePage}
      />

      {showModal && (
        <AssignUsersToModelModal
          model={selectedModel}
          onSubmit={onModalSubmit}
        />
      )}
    </>
  );
};
export default ModelsListAdmin;
