import React, { useEffect, useState } from "react";
import { updateACustomField } from "../../redux/modules/admin/actions";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import PageHeader from "../../Components/ui/pageElementes/PageHeader";
import FaceIcon from "@mui/icons-material/Face";
import {
  Autocomplete,
  Checkbox,
  FormControlLabel,
  FormGroup,
  TextField,
} from "@mui/material";
import { Box } from "@mui/system";
import MainButton from "../../Components/ui/buttons/MainButton";
import { ControlledInput as Input } from "../../Components/ui/forms/ControlledInput";
import Chip from "@mui/material/Chip";

const ViewCustomFields = (props) => {
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const { selectedCustomField, categories } = useSelector(
    (state) => state.adminData
  );

  const [isFilter, setIsFilter] = useState(
    selectedCustomField.customField.isFilter
  );

  const [dropdownList, setDropdownList] = useState(false);
  const [multiSelect, setMultiSelect] = useState(
    selectedCustomField.customField.isMultiple
  );

  const [parentCategoryValues, setParentCategoryValues] = React.useState(
    selectedCustomField.subCategory
  );

  useEffect(() => {
    setParentCategoryValues(selectedCustomField.subCategory);
    if (selectedCustomField.customField.type === "Dropdown List") {
      setDropdownList(true);
    }
  }, [categories]);

  const updateCustomField = (data) => {
    const { name, type } = data;
    const customField = {
      name,
      subCategoryId: selectedCustomField.subcategory.id,
      type: type || selectedCustomField.customField.type,
      isFilter: isFilter,
      isMultiple: multiSelect,
      dropdownList: data.dropdownList ? data.dropdownList : [],
    };

    dispatch(
      updateACustomField(
        selectedCustomField.customField._id,
        customField,
        callback
      )
    );
  };

  const handleChange = (event) => {
    setMultiSelect(event.target.checked);
  };

  const showDropdownList = () => {
    if (dropdownList) {
      return (
        <div className="w-full flex items-center justify-center flex-col sm:flex-row ">
          <div className="w-full px-2">
            <h3 className="text-lg flex itmes-start font-medium leading-6 text-gray-900">
              Dropdown Options
            </h3>
            <Controller
              control={control}
              render={({
                field,
                fieldState: { invalid, isTouched, isDirty, error },
                formState,
              }) => {
                return (
                  <div
                    className={`w-full flex justify-center  items-center py-3 px-7  border border-fade rounded-4xl my-4`}
                  >
                    <Autocomplete
                      disablePortal
                      multiple
                      className="w-full"
                      id="dropdown-box"
                      onChange={(e, val, reason) => {
                        let arr = [];
                        val.map((value) => {
                          if (value) {
                            arr.push(value);
                          } else {
                            arr = [];
                          }
                        });

                        field.onChange(arr);
                      }}
                      options={[]}
                      defaultValue={() => {
                        field.onChange(
                          selectedCustomField.customField.dropdownList
                        );
                        return selectedCustomField.customField.dropdownList;
                      }}
                      getOptionLabel={(option) => option}
                      freeSolo
                      renderTags={(value, getTagProps) =>
                        value.map((option, index) => (
                          <Chip
                            variant="outlined"
                            label={option}
                            key={option}
                            {...getTagProps({ index })}
                          />
                        ))
                      }
                      renderOption={(props, option) => (
                        <Box
                          component="li"
                          sx={{
                            "& > img": {
                              mr: 2,
                              flexShrink: 0,
                            },
                          }}
                          {...props}
                          key={option}
                        >
                          <p> {option} </p>
                        </Box>
                      )}
                      sx={{ width: "100%" }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder="Type Dropdown List"
                          variant="standard"
                          inputProps={{
                            ...params.inputProps,
                            autoComplete: "new-password",
                          }}
                        />
                      )}
                    />
                  </div>
                );
              }}
              name="dropdownList"
              rules={{
                required: {
                  value: true,
                  message: "Dropdown Options is required",
                },
              }}
            />

            <p className="text-xs text-red-500 " style={{ minHeight: "1rem" }}>
              {errors.dropdownList && errors.dropdownList.message}
            </p>
          </div>
          <div className={"w-full flex justify-start"}>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    defaultChecked={selectedCustomField.customField.isMultiple}
                    onChange={handleChange}
                  />
                }
                label="Multiselect"
              />
            </FormGroup>
          </div>
        </div>
      );
    }
  };

  const onToggleChange = () => {
    setIsFilter(!isFilter);
  };

  const callback = () => {
    navigate("/custom-fields");
  };

  return (
    <div className="w-full h-full flex flex-col justify-around">
      <PageHeader
        backLink={"/custom-fields"}
        Profile={<FaceIcon className="text-white " />}
        buttonSecondary={true}
        title={`Update Custom Field: ${selectedCustomField.customField.name}`}
        toggleChecked={isFilter}
        onToggleChange={onToggleChange}
        toggleLabel={"Is Filter?"}
      />
      <div className="py-2 align-middle inline-block min-w-full w-full">
        <form className="shadowBox overflow-hidden bg-white px-2 sm:px-6 lg:px-8 py-4 flex flex-col w-full sm:flex-row sm:justify-between flex-wrap">
          <div className="w-full flex items-center justify-center flex-col sm:flex-row ">
            <div className="w-full sm:w-1/2 px-2">
              <h3 className="text-lg flex itmes-start font-medium leading-6 text-gray-900">
                Category
              </h3>
              <Controller
                control={control}
                render={({
                  field: { onChange, onBlur, value, name, ref },
                  fieldState: { invalid, isTouched, isDirty, error },
                  formState,
                }) => {
                  return (
                    <div
                      className={`w-full flex justify-center  items-center py-3 px-7  border border-fade rounded-4xl my-4`}
                    >
                      <Autocomplete
                        disabled={true}
                        disablePortal
                        className="w-full"
                        value={selectedCustomField.category}
                        options={categories || []}
                        defaultValue={() => {
                          onChange(selectedCustomField.category);
                        }}
                        getOptionLabel={(option) => option.name}
                        renderOption={(props, option) => (
                          <Box
                            component="li"
                            sx={{
                              "& > img": {
                                mr: 2,
                                flexShrink: 0,
                              },
                            }}
                            {...props}
                          >
                            <div className="flex items-center justify-start flex-row">
                              <p> {option.name} </p>
                            </div>
                          </Box>
                        )}
                        sx={{ width: "100%" }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            placeholder="Select a category"
                            variant="standard"
                            inputProps={{
                              ...params.inputProps,
                              autoComplete: "new-password",
                            }}
                          />
                        )}
                      />
                    </div>
                  );
                }}
                name="category"
              />

              <p
                className="text-xs text-red-500 "
                style={{ minHeight: "1rem" }}
              >
                {errors?.category?.message}
              </p>
            </div>
            <div className="w-full sm:w-1/2 px-2">
              <h3 className="text-lg flex itmes-start font-medium leading-6 text-gray-900">
                Subcategory
              </h3>
              <Controller
                control={control}
                render={({
                  field: { onChange, onBlur, value, name, ref },
                  fieldState: { invalid, isTouched, isDirty, error },
                  formState,
                }) => {
                  return (
                    <div
                      className={`w-full flex justify-center  items-center py-3 px-7  border border-fade rounded-4xl my-4`}
                    >
                      <Autocomplete
                        disabled={true}
                        disablePortal
                        className="w-full"
                        options={parentCategoryValues || []}
                        value={selectedCustomField.subcategory}
                        defaultValue={() => {
                          onChange(selectedCustomField.subcategory);
                        }}
                        noOptionsText={
                          parentCategoryValues
                            ? "No options"
                            : "Select a parent category first"
                        }
                        getOptionLabel={(option) => option.name}
                        renderOption={(props, option) => (
                          <Box
                            component="li"
                            sx={{
                              "& > img": {
                                mr: 2,
                                flexShrink: 0,
                              },
                            }}
                            {...props}
                          >
                            <div className="flex items-center justify-start flex-row">
                              <p> {option.name} </p>
                            </div>
                          </Box>
                        )}
                        sx={{ width: "100%" }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            placeholder="Select a subcategory"
                            variant="standard"
                            inputProps={{
                              ...params.inputProps,
                              autoComplete: "new-password",
                            }}
                          />
                        )}
                      />
                    </div>
                  );
                }}
                name="subcategory"
              />

              <p
                className="text-xs text-red-500 "
                style={{ minHeight: "1rem" }}
              >
                {errors.subcategory && errors.subcategory.message}
              </p>
            </div>
          </div>
          <div className="w-full flex items-center mt-2 justify-center flex-col sm:flex-row ">
            <div className="w-full sm:w-1/2 px-2">
              <h3 className="text-lg flex itmes-start font-medium leading-6 text-gray-900">
                Name
                <span className="text-xs text-red-500">*</span>
              </h3>
              <span className="text-xs text-blue-400">Mandatory field</span>
              <div
                className={`w-full flex justify-center  items-center py-2 px-7  border border-fade rounded-4xl my-4`}
              >
                <Input
                  id="name-phone"
                  defaultValue={selectedCustomField.customField.name}
                  control={control}
                  fieldName="name"
                  placeholder={"Name of the Custom Field"}
                  type="text"
                />
              </div>
              <p
                className="text-xs text-red-500 "
                style={{ minHeight: "1rem" }}
              >
                {errors.name && errors.name.message}
              </p>
            </div>
            <div className="w-full sm:w-1/2 px-2">
              <h3 className="text-lg flex itmes-start font-medium leading-6 text-gray-900">
                Type
                <span className="text-xs text-red-500">*</span>
              </h3>
              <span className="text-xs text-blue-400">Mandatory field</span>
              <Controller
                control={control}
                render={({
                  field: { onChange, onBlur, value, name, ref },
                  fieldState: { invalid, isTouched, isDirty, error },
                  formState,
                }) => {
                  return (
                    <div
                      className={`w-full flex justify-center  items-center py-3 px-7  border border-fade rounded-4xl my-4`}
                    >
                      <Autocomplete
                        disablePortal
                        className="w-full"
                        onChange={(e, val) => {
                          if (val) {
                            if (val === "Dropdown List") {
                              setDropdownList(true);
                            } else {
                              setDropdownList(false);
                            }
                            onChange(val);
                          } else {
                            onChange(selectedCustomField.customField.type);
                          }
                        }}
                        options={["Number", "String", "Dropdown List"]}
                        defaultValue={() => {
                          onChange(selectedCustomField.customField.type);
                          return selectedCustomField.customField.type;
                        }}
                        getOptionLabel={(option) => option}
                        renderOption={(props, option) => (
                          <Box
                            component="li"
                            sx={{
                              "& > img": {
                                mr: 2,
                                flexShrink: 0,
                              },
                            }}
                            {...props}
                          >
                            <div className="flex items-center justify-start flex-row">
                              <p> {option} </p>
                            </div>
                          </Box>
                        )}
                        sx={{ width: "100%" }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            placeholder="Select a type"
                            variant="standard"
                            inputProps={{
                              ...params.inputProps,
                              autoComplete: "new-password",
                            }}
                          />
                        )}
                      />
                    </div>
                  );
                }}
                name="type"
                rules={{
                  required: {
                    value: false,
                    message: "Type is required",
                  },
                }}
              />

              <p
                className="text-xs text-red-500 "
                style={{ minHeight: "1rem" }}
              >
                {errors.type && errors.type.message}
              </p>
            </div>
          </div>

          {showDropdownList()}

          <div className="w-full p-2">
            <MainButton
              action={handleSubmit(updateCustomField)}
              title={"Update"}
            />
          </div>
        </form>
      </div>
    </div>
  );
};

export default ViewCustomFields;
