import React, { Fragment, useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Checkbox } from "@mui/material";
import { useHttp } from "../../hooks/useHttp";
import { getClientsRequest } from "../../api/modules/clients";
import { ADMIN_ASSIGN_CLIENTS_TABLE_COLUMNS } from "./assignClientsToEndUser.constants";

const AssignClientsToEndUserList = ({ endUser, setAssignedClients }) => {
  const { http, isLoading } = useHttp();
  const [clients, setClients] = useState([]);
  const [checkedClients, setCheckedClients] = useState([]);

  const getClients = () => {
    http(() => {
      getClientsRequest().then((res) => setClients(res));
    });
  };

  useEffect(() => {
    getClients();
  }, []);

  useEffect(() => {
    if (!endUser || !clients) return;

    const initialCheckedClients = clients
      .filter((client) => client.connectedEndUsers?.includes(endUser.id))
      .map((client) => {
        return client.id;
      });
    setCheckedClients(initialCheckedClients);
  }, [clients, endUser]);

  const handleClickAssignments = (clientId) => {
    const index = checkedClients.indexOf(clientId);
    if (index > -1) {
      setCheckedClients((prev) => prev.filter((e) => e !== clientId));
    } else {
      setCheckedClients([...checkedClients, clientId]);
    }
  };
  useEffect(() => {
    setAssignedClients(checkedClients);
  }, [checkedClients]);

  const columns = [
    ...ADMIN_ASSIGN_CLIENTS_TABLE_COLUMNS,
    {
      field: "isAssigned",
      headerName: "Assigned?",
      sortable: true,
      filterable: false,
      headerAlign: "center",
      width: 100,
      renderCell: (params) => (
        <Checkbox
          checked={checkedClients.includes(params.id)}
          onChange={() => handleClickAssignments(params.id)}
        />
      ),
    },
  ];

  const clientsRows = clients.map((client) => ({
    ...client,
    isAssigned: checkedClients.includes(client.id),
  }));
  return (
    <>
      <div className="flex flex-col">
        <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div className="shadowBox overflow-hidden ">
              <div style={{ width: "100%" }}>
                <DataGrid
                  loading={isLoading}
                  style={{ border: "0", padding: "10px 20px" }}
                  rows={clientsRows || []}
                  columns={columns}
                  checkboxSelection={false}
                  pageSize={10}
                  autoHeight={true}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AssignClientsToEndUserList;
