import React from "react";
import { Link } from "react-router-dom";
import googlePlay from "../../../assets/icons/google-play.png";
import appStore from "../../../assets/icons/ios.png";
import logo from "../../../assets/images/Logo.png";
import CopyRight from "../../ui/pageElementes/Copyright";
const Footer = () => {
  return (
    <>
      <div className="flex w-full justify-center flex-row items-top flex-wrap mb-5">
        <div className="w-1/2 lg:w-1/5 text-center">
          <Link to="/">
            {" "}
            <img src={logo}></img>
          </Link>

          <p>Make Your Designs Come to Life!</p>
        </div>

        <div className="w-1/2 lg:w-1/5 text-center pt-8 space-y-1">
          <h3 className="text-lg">Company</h3>
          <br />
          <p>
            XRETEH SRL
            <br />
            Str. GHEORGHE SINCAI, Nr. 33, Municipiul Baia Mare , Judet Maramures
            430311, Romania
            <br />
            <a href="mailto:office@xreteh.com">office@xreteh.com</a>
          </p>
        </div>

        <div className="w-1/2 lg:w-1/5 text-center pt-8 space-y-1">
          <h3 className="text-lg">More</h3>
          <br />
          <div>
            <Link to="/privacy">Privacy Policy</Link>
          </div>
          <div>
            <Link to="/terms">Terms and Agreements</Link>
          </div>
          <div>
            <Link to="/disclaimer">Disclaimer</Link>
          </div>
          <div>
            <a
              href="https://www.unrealoptic.com/"
              target="_blank"
              rel="noreferrer"
            >
              Unreal Optic
            </a>
          </div>
        </div>

        <div className="w-1/2 lg:w-1/5 text-center pt-8 space-y-1">
          <h3 className="text-lg">Discover our Apps</h3>
          <br />
          <div>
            <a
              href="https://play.google.com/console"
              target="_blank"
              rel="noreferrer"
            >
              <img className="w-1/2 m-auto" src={googlePlay} />
            </a>
          </div>
          <div>
            <a
              href="https://play.google.com/console"
              target="_blank"
              rel="noreferrer"
            >
              <img className="w-1/2 m-auto" src={appStore} />
            </a>
          </div>
        </div>
        {/*
          <div className="w-1/2 lg:w-1/5 text-center pt-8 space-y-1">
            <h3 className="text-lg">Discover our apps</h3>
            <br />
            <div><a href="">Lorem Ipsum</a></div>
            <div><a href="">Lorem Ipsum</a></div>
            <div><a href="">Lorem Ipsum</a></div>
          </div> */}
      </div>
      <CopyRight />
    </>
  );
};

export default Footer;
