import React, { useState, useEffect } from "react";
import {
  getCategories,
  updateACategory,
} from "../../redux/modules/admin/actions";
import { useSelector, useDispatch } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import { ControlledInput as Input } from "../../Components/ui/forms/ControlledInput";
import { Controller, useForm } from "react-hook-form";
import PageHeader from "../../Components/ui/pageElementes/PageHeader";
import FaceIcon from "@mui/icons-material/Face";
import SuccessModal from "../../Components/ui/modals/SuccessModal";
import { Autocomplete, TextField } from "@mui/material";
import Chip from "@mui/material/Chip";
import { Box } from "@mui/system";
import MainButton from "../../Components/ui/buttons/MainButton";

const ViewCategoriesView = (props) => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { selectedCategory, error } = useSelector((state) => state.adminData);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    dispatch(getCategories());
  }, []);

  const updateCategory = (data) => {
    const updatedCategory = selectedCategory;
    updatedCategory.name = data.name;
    updatedCategory.subcategories = data.subcategories.map((category) => {
      if (!category.name) {
        return { name: category };
      } else {
        return category;
      }
    });
    dispatch(updateACategory(id, updatedCategory, callback));
  };

  const callback = () => {
    navigate("/categories");
  };

  const backendErrors = () => {
    return error.map((e) => (
      <li className="text-xs text-red-500 " key={e.code}>
        {e.message}
        <br />
      </li>
    ));
  };

  return (
    <div className="w-full h-full flex flex-col justify-around">
      <PageHeader
        backLink={"/categories"}
        Profile={<FaceIcon className="text-white " />}
        buttonSecondary={true}
        title={`Category ${selectedCategory.name}`}
      />
      <div className="py-2 align-middle inline-block min-w-full w-full">
        <form className="shadowBox overflow-hidden bg-white px-2 sm:px-6 lg:px-8 py-4 flex flex-col w-full sm:flex-row sm:justify-between flex-wrap">
          <div className="w-full flex items-center justify-center flex-col sm:flex-row ">
            <div className="w-full sm:w-1/2 px-2">
              <h3 className="text-lg flex itmes-start font-medium leading-6 text-gray-900">
                Name
              </h3>
              <div
                className={`w-full flex justify-center  items-center py-2 px-7  border border-fade rounded-4xl my-4`}
              >
                <Input
                  id="category-name"
                  control={control}
                  fieldName="name"
                  type="text"
                  defaultValue={selectedCategory.name}
                  placeholder={"Name"}
                  rules={{
                    minLength: {
                      value: 3,
                      message: "The min length of the name is 3",
                    },
                  }}
                />
              </div>
              <p
                className="text-xs text-red-500 "
                style={{ minHeight: "1rem" }}
              >
                {errors.name && errors.name.message}
              </p>
            </div>
            <div className="w-1/2 flex items-center justify-center flex-col sm:flex-row ">
              <div className="w-full px-2">
                <h3 className="text-lg flex itmes-start font-medium leading-6 text-gray-900">
                  Subcategory
                </h3>
                <Controller
                  control={control}
                  render={({
                    field,
                    fieldState: { invalid, isTouched, isDirty, error },
                    formState,
                  }) => {
                    return (
                      <div
                        className={`w-full flex justify-center  items-center py-3 px-7  border border-fade rounded-4xl my-4`}
                      >
                        <Autocomplete
                          disablePortal
                          multiple
                          className="w-full"
                          id="categories-box"
                          onChange={(e, val, reason) => {
                            let arr = [];
                            val.map((value) => {
                              if (value) {
                                arr.push(value);
                              } else {
                                arr = [];
                              }
                            });
                            field.onChange(arr);
                          }}
                          options={[]}
                          defaultValue={() => {
                            field.onChange(selectedCategory.subcategories);
                            return selectedCategory.subcategories;
                          }}
                          getOptionLabel={(option) => option.name || option}
                          freeSolo
                          renderTags={(value, getTagProps) =>
                            value.map((option, index) => (
                              <Chip
                                variant="outlined"
                                label={option.name || option}
                                key={(option) => option.name || option}
                                {...getTagProps({ index })}
                              />
                            ))
                          }
                          renderOption={(props, option) => (
                            <Box
                              component="li"
                              sx={{
                                "& > img": {
                                  mr: 2,
                                  flexShrink: 0,
                                },
                              }}
                              {...props}
                              key={option}
                            >
                              <p> {option} </p>
                            </Box>
                          )}
                          sx={{ width: "100%" }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              placeholder="Select Categories"
                              variant="standard"
                              inputProps={{
                                ...params.inputProps,
                                autoComplete: "new-password",
                              }}
                            />
                          )}
                        />
                      </div>
                    );
                  }}
                  name="subcategories"
                  rules={{
                    required: {
                      value: true,
                      message: "Subcategories are required",
                    },
                  }}
                />

                <p
                  className="text-xs text-red-500 "
                  style={{ minHeight: "1rem" }}
                >
                  {errors.subcategories && errors.subcategories.message}
                </p>
              </div>
            </div>
          </div>
          <div className="w-full p-2">
            <MainButton action={handleSubmit(updateCategory)} title={"Save"} />
          </div>

          <SuccessModal
            visibility={showSuccessModal}
            setVisibility={setShowSuccessModal}
            title={"The update operation succeeded"}
          />
          <ul className="w-full flex flex-col justify-center items-center">
            {backendErrors()}
          </ul>
        </form>
      </div>
    </div>
  );
};

export default ViewCategoriesView;
