import React, { Fragment, useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Checkbox } from "@mui/material";
import { useHttp } from "../../hooks/useHttp";
import { getPartnersRequest } from "../../api/modules/partners";
import { ADMIN_ASSIGN_PARTNER_TABLE_COLUMNS } from "./assignPartner.constants";

const AssignPartnerList = ({ client, setAssignedPartner }) => {
  const { http, isLoading } = useHttp();
  const [partners, setPartners] = useState([]);
  const [checkedPartner, setCheckedPartner] = useState();

  const getPartners = () => {
    http(() => {
      getPartnersRequest().then((res) => setPartners(res));
    });
  };

  useEffect(() => {
    getPartners();
  }, []);

  useEffect(() => {
    if (!client || !partners) return;

    const initialCheckedPartner = partners.find(
      (partner) => client.partnerId === partner.id
    );

    setCheckedPartner(initialCheckedPartner?.id);
  }, [client, partners]);

  useEffect(() => {
    setAssignedPartner(checkedPartner);
  }, [checkedPartner]);

  const columns = [
    ...ADMIN_ASSIGN_PARTNER_TABLE_COLUMNS,
    {
      field: "isAssigned",
      headerName: "Assigned?",
      sortable: true,
      filterable: true,
      headerAlign: "center",
      width: 100,
      renderCell: (params) => (
        <Checkbox
          checked={checkedPartner === params.id}
          onChange={() =>
            checkedPartner === params.id
              ? setCheckedPartner()
              : setCheckedPartner(params.id)
          }
        />
      ),
    },
  ];

  const partnersRows = partners.map((partner) => ({
    ...partner,
    isAssigned: checkedPartner && checkedPartner === partner.id,
  }));

  return (
    <>
      <div className="flex flex-col">
        <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div className="shadowBox overflow-hidden ">
              <div style={{ width: "100%" }}>
                <DataGrid
                  loading={isLoading}
                  style={{ border: "0", padding: "10px 20px" }}
                  rows={partnersRows || []}
                  columns={columns}
                  checkboxSelection={false}
                  pageSize={10}
                  autoHeight={true}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AssignPartnerList;
