import React, { Fragment, useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import PageHeader from "../../Components/ui/pageElementes/PageHeader";
import GroupIcon from "@mui/icons-material/Group";
import SquareButton from "../../Components/ui/buttons/SquareButton";
import RemoveModal from "../../Components/ui/modals/RemoveModal";
import { useHttp } from "../../hooks/useHttp";
import {
  getAllCustomFieldsRequest,
  removeCustomFieldRequest,
} from "../../api/modules/customFields";
import { ADMIN_CUSTOM_FIELDS_TABLE_COLUMNS } from "./adminCustomFields.constants";
import { useDispatch } from "react-redux";

import { selectCustomField } from "../../redux/modules/admin/actions";

const AdminCustomFieldsList = ({ data }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { http, isLoading } = useHttp();
  const [removeModal, setRemoveModal] = useState(false);
  const [removeParams, setRemoveParams] = useState();
  const [customFields, setCustomFields] = useState();

  useEffect(() => {
    getCustomFields().then((customFields) => {
      return setCustomFields(customFields);
    });
  }, []);

  const removeSubmit = async () => {
    await http(() => removeCustomFieldRequest(removeParams));

    getCustomFields().then((customFields) => {
      setCustomFields(customFields);
    });
  };

  const getCustomFields = async () => {
    return await http(() => getAllCustomFieldsRequest());
  };

  const goToEdit = ({ category, subcategory, customField }) => {
    dispatch(selectCustomField({ category, subcategory, customField }));
    navigate(`/custom-fields/edit`);
  };

  const columns = [
    ...ADMIN_CUSTOM_FIELDS_TABLE_COLUMNS,
    {
      field: "actions",
      headerName: "Actions",
      sortable: false,
      filterable: false,
      headerAlign: "center",
      width: 200,
      renderCell: (params) => (
        <Fragment key={params.id}>
          <SquareButton
            action={() => {
              goToEdit({
                category: {
                  id: params.row.category.id,
                  name: params.row.category.name,
                },
                subcategory: {
                  id: params.row.subCategory._id,
                  name: params.row.subCategory.name,
                },
                customField: params.row,
              });
            }}
            extraClasses={"text-xs"}
            padding={"px-2 py-1"}
            title="Edit"
            fill={true}
          />
          <SquareButton
            padding={"px-2 py-1"}
            extraClasses={"text-xs"}
            action={() => {
              setRemoveParams({
                customFieldId: params.row._id,
                categoryId: params.row.categoryId,
                subCategoryId: params.row.subCategoryId,
              });
              setRemoveModal(true);
            }}
            title="Delete"
            fill={false}
          />
        </Fragment>
      ),
    },
  ];

  return (
    <div className="w-full h-full flex flex-col justify-around">
      <div className="w-full h-full flex flex-col justify-around">
        <PageHeader
          Icon={GroupIcon}
          action={() => navigate("/custom-fields/new")}
          buttonTitle="Add Custom Field"
        />
        <div className="flex flex-col">
          <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
              <div className="shadowBox overflow-hidden ">
                <div style={{ width: "100%" }}>
                  <DataGrid
                    loading={isLoading}
                    getRowId={(row) => row._id}
                    style={{ border: "0", padding: "10px 20px" }}
                    rows={customFields || []}
                    columns={columns}
                    checkboxSelection={false}
                    pageSize={10}
                    autoHeight={true}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <RemoveModal
          buttonText="Remove Custom Field"
          visibility={removeModal}
          setVisibility={setRemoveModal}
          submitAction={removeSubmit}
          title="Are you sure you want to remove this custom field?"
          description={`This action is irreversible.`}
        />
      </div>
    </div>
  );
};

export default AdminCustomFieldsList;
