import React, { useState, useEffect } from "react";
import FaceIcon from "@mui/icons-material/Face";
import { useParams, useNavigate } from "react-router-dom";
import { ControlledInput as Input } from "../../Components/ui/forms/ControlledInput";
import MainButton from "../../Components/ui/buttons/MainButton";
import { useForm } from "react-hook-form";
import PageHeader from "../../Components/ui/pageElementes/PageHeader";
import RemoveModal from "../../Components/ui/modals/RemoveModal";
import SuccessModal from "../../Components/ui/modals/SuccessModal";
import {
  createSubscriptionPlanRequest,
  deleteSubscriptionPlanRequest,
  getSubscriptionPlanRequest,
  updateSubscriptionPlanRequest,
} from "../../api/modules/subscriptionPlans";
import { useHttp } from "../../hooks/useHttp";
import LoadingIcon from "../../Components/ui/icons/LoadingIcon";

const AdminSubscriptionPlansForm = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { http, isLoading, backendErrors } = useHttp();
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [subscriptionPlan, setSubscriptionPlan] = useState();
  const [removeModal, setRemoveModal] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [isTrial, setIsTrial] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (!id) return;

    http(() =>
      getSubscriptionPlanRequest(id).then((res) => {
        setSubscriptionPlan(res);
        setIsTrial(res?.isTrial);
      })
    );
  }, []);

  const removeSubmit = async () => {
    await http(() => deleteSubscriptionPlanRequest(id));
  };

  const prepareData = (data) => {
    return {
      name: data.name,
      isTrial: isTrial,
      description: data.description,
      diskQuota: data.diskQuota,
      monthlyPrice: +data.monthly.price,
      yearlyPrice: +data.yearly.price,
    };
  };

  const onSubmit = async (data) => {
    await http(() =>
      id
        ? updateSubscriptionPlanRequest(id, prepareData(data))
        : createSubscriptionPlanRequest(prepareData(data))
    );

    navigate("/subscription-plans");
  };

  if (id && !subscriptionPlan) {
    return <LoadingIcon />;
  }

  return (
    <div className="w-full h-full flex flex-col justify-around">
      <PageHeader
        backLink={"/subscription-plans"}
        Profile={<FaceIcon className="text-white " />}
        buttonSecondary={true}
        toggleChecked={isTrial}
        onToggleChange={() => setIsTrial(!isTrial)}
        toggleLabel={"Is Trial?"}
        title={
          subscriptionPlan
            ? `Subscription Plan: ${subscriptionPlan?.name}`
            : "New Subscription Plan"
        }
      />

      <div className="py-2 align-middle inline-block min-w-full w-full">
        <form className="shadowBox overflow-hidden bg-white px-2 sm:px-6 lg:px-8 py-4 flex flex-col w-full sm:flex-row sm:justify-between flex-wrap">
          <div className="w-full mb-10 flex items-center justify-center flex-col sm:flex-row ">
            <div className="w-full sm:w-1/2 px-2">
              <h3 className="text-lg flex itmes-start font-medium leading-6 text-gray-900">
                Name
              </h3>
              <div
                className={`w-full flex justify-center  items-center py-2 px-7  border border-fade rounded-4xl my-4`}
              >
                <Input
                  id="subscriptionPlan-name"
                  control={control}
                  fieldName="name"
                  type="text"
                  defaultValue={subscriptionPlan?.name}
                  placeholder={"Name"}
                  rules={{
                    required: {
                      value: true,
                      message: "is required",
                    },
                    minLength: {
                      value: 4,
                      message: "The min length of the name is 4",
                    },
                  }}
                />
              </div>
              <p
                className="text-xs text-red-500 "
                style={{ minHeight: "1rem" }}
              >
                {errors?.name?.message}
              </p>
            </div>

            <div className="w-full sm:w-1/2 px-2">
              <h3 className="text-lg flex itmes-start font-medium leading-6 text-gray-900">
                Description
              </h3>
              <div
                className={`w-full flex justify-center  items-center py-2 px-7  border border-fade rounded-4xl my-4`}
              >
                <Input
                  id="subscriptionPlan-description"
                  control={control}
                  fieldName="description"
                  type="text"
                  defaultValue={subscriptionPlan?.description}
                  placeholder={"Type description"}
                  rules={{
                    required: {
                      value: true,
                      message: "is required",
                    },
                    minLength: {
                      value: 10,
                      message: "The min length is 10 chars",
                    },
                  }}
                />
              </div>
              <p
                className="text-xs text-red-500 "
                style={{ minHeight: "1rem" }}
              >
                {errors?.description?.message}
              </p>
            </div>
          </div>
          <div className="w-full mb-10 flex items-center justify-center flex-col sm:flex-row ">
            <div className="w-full sm:w-1/2 px-2">
              <h3 className="text-lg flex itmes-start font-medium leading-6 text-gray-900">
                Disk Quota
              </h3>
              <div
                className={`w-full flex justify-center  items-center py-2 px-7  border border-fade rounded-4xl my-4`}
              >
                <Input
                  id="subscriptionPlan-diskQuota"
                  control={control}
                  fieldName="diskQuota"
                  type="number"
                  defaultValue={subscriptionPlan?.diskQuota}
                  placeholder={"512 MB"}
                  rules={{
                    required: {
                      value: true,
                      message: "is required",
                    },
                  }}
                />
              </div>
              <p
                className="text-xs text-red-500 "
                style={{ minHeight: "1rem" }}
              >
                {errors?.diskQuota?.message}
              </p>
            </div>

            <div className="w-full sm:w-1/2 px-2">
              <h3 className="text-lg flex itmes-start font-medium leading-6 text-gray-900">
                Monthly Price
              </h3>
              <div
                className={`w-full flex justify-center  items-center py-2 px-7  border border-fade rounded-4xl my-4`}
              >
                <Input
                  id="subscriptionPlan-monthlyPrice"
                  control={control}
                  fieldName="monthly.price"
                  type="number"
                  defaultValue={subscriptionPlan?.monthly?.price}
                  placeholder={"120 $"}
                  rules={{
                    required: {
                      value: true,
                      message: "is required",
                    },
                  }}
                />
              </div>
              <p
                className="text-xs text-red-500 "
                style={{ minHeight: "1rem" }}
              >
                {errors?.monthly?.price?.message}
              </p>
            </div>
            <div className="w-full sm:w-1/2 px-2">
              <h3 className="text-lg flex itmes-start font-medium leading-6 text-gray-900">
                Yearly Price
              </h3>
              <div
                className={`w-full flex justify-center  items-center py-2 px-7  border border-fade rounded-4xl my-4`}
              >
                <Input
                  id="subscriptionPlan-yearlyPrice"
                  control={control}
                  fieldName="yearly.price"
                  type="number"
                  defaultValue={subscriptionPlan?.yearly?.price}
                  placeholder={"120 $"}
                  rules={{
                    required: {
                      value: true,
                      message: "is required",
                    },
                  }}
                />
              </div>
              <p
                className="text-xs text-red-500 "
                style={{ minHeight: "1rem" }}
              >
                {errors?.yearly?.price?.message}
              </p>
            </div>
          </div>
          <div className="w-full p-2">
            <MainButton
              isLoading={isLoading}
              action={handleSubmit(onSubmit)}
              title={"Save"}
            />
          </div>
          <RemoveModal
            buttonText="Remove SubscriptionPlan"
            visibility={removeModal}
            setVisibility={setRemoveModal}
            submitAction={removeSubmit}
            title="Are you sure you want to remove this subscription plan?"
            description={`This action is irreversible.`}
          />
          <SuccessModal
            visibility={showSuccessModal}
            setVisibility={setShowSuccessModal}
            title={"The update operation succeeded"}
          />
          <ul className="w-full flex flex-col justify-center items-center">
            {(backendErrors || []).map((e) => (
              <li className="text-xs text-red-500 " key={e.code}>
                {e.message}
                <br />
              </li>
            ))}
          </ul>
        </form>
      </div>
    </div>
  );
};

export default AdminSubscriptionPlansForm;
