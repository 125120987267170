import React, { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import PageHeader from "../../Components/ui/pageElementes/PageHeader";
import GroupIcon from "@mui/icons-material/Group";
import SquareButton from "../../Components/ui/buttons/SquareButton";
import RemoveModal from "../../Components/ui/modals/RemoveModal";
import { useHttp } from "../../hooks/useHttp";
import {
  deleteEndUserRequest,
  getEndUsersRequest,
  updateEndUserStatusRequest,
} from "../../api/modules/endUser";
import { ADMIN_ENDUSERS_TABLE_COLUMNS } from "./adminEndUsers.constant";
import AssignModelsToUserModal from "../../Components/ui/modals/AssignModelsToUserModal";
import ActivateUserModal from "../../Components/ui/modals/ActivateUserModal";

const AdminEndUsersList = ({ data }) => {
  const navigate = useNavigate();
  const { http, isLoading } = useHttp();
  const [removeModal, setRemoveModal] = useState(false);
  const [selectedID, setSelectedID] = useState();
  const [endUsers, setEndUsers] = useState();
  const [showActivateEndUserModal, setShowActivateEndUserModal] =
    useState(false);
  const [showModelAssignmentModal, setShowModelAssignmentModal] =
    useState(false);

  const getEndUsers = () => {
    http(() => {
      getEndUsersRequest().then((res) => {
        return setEndUsers(res);
      });
    });
  };

  useEffect(() => {
    getEndUsers();
  }, [showActivateEndUserModal]);

  const handleActivateEndUser = () => {
    http(() => {
      updateEndUserStatusRequest(selectedID, { status: true });
    });
  };

  const removeSubmit = async () => {
    await http(() => {
      deleteEndUserRequest(selectedID);
    });

    getEndUsers();
  };

  const columns = [
    ...ADMIN_ENDUSERS_TABLE_COLUMNS,
    {
      field: "actions",
      headerName: "Actions",
      sortable: false,
      filterable: false,
      headerAlign: "center",
      width: 200,
      renderCell: (params) => (
        <div
          key={params.id}
          style={{ flexDirection: "column", display: "flex", gap: "10px" }}
        >
          {params.row.status ? (
            <SquareButton
              padding={"px-6 py-1"}
              extraClasses={`text-xs ${!params.row.status && "invisible"}`}
              action={() => {
                setSelectedID(params.id);
                setShowModelAssignmentModal(true);
              }}
              title="Assign Models"
              fill={false}
            />
          ) : (
            <SquareButton
              padding={"px-7 py-1"}
              extraClasses={`text-xs`}
              action={() => {
                setSelectedID(params.id);
                setShowActivateEndUserModal(true);
              }}
              title="Activate User"
              fill={false}
            />
          )}
          <SquareButton
            action={() => {
              navigate(`/end-users/${params.id}`);
            }}
            extraClasses={"text-xs"}
            padding={"px-11 py-1"}
            title="Edit"
            fill={true}
          />
          <SquareButton
            padding={"px-11 py-1"}
            extraClasses={"text-xs"}
            action={() => {
              setSelectedID(params.id);
              setRemoveModal(true);
            }}
            title="Delete"
            fill={false}
          />
        </div>
      ),
    },
  ];

  return (
    <div className="w-full h-full flex flex-col justify-around">
      <div className="w-full h-full flex flex-col justify-around">
        <PageHeader
          Icon={GroupIcon}
          action={() => navigate("/end-users/new")}
          buttonTitle="Add End User"
        />
        <div className="flex flex-col">
          <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
              <div className="shadowBox overflow-hidden ">
                <div style={{ width: "100%" }}>
                  <DataGrid
                    loading={isLoading}
                    style={{ border: "0", padding: "10px 20px" }}
                    rows={endUsers || []}
                    columns={columns}
                    checkboxSelection={false}
                    pageSize={10}
                    autoHeight={true}
                    rowHeight={120}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <RemoveModal
          buttonText="Remove End User"
          visibility={removeModal}
          setVisibility={setRemoveModal}
          submitAction={removeSubmit}
          title="Are you sure you want to remove this end user?"
          description={`This action is irreversible.`}
        />

        <AssignModelsToUserModal
          isClient={false}
          visibility={showModelAssignmentModal}
          setVisibility={setShowModelAssignmentModal}
          user={(endUsers || []).find((user) => user.id === selectedID)}
          onSubmit={() => {
            setSelectedID(null);
            setShowModelAssignmentModal(false);
            getEndUsers();
          }}
        />
        {showActivateEndUserModal && (
          <ActivateUserModal
            buttonText="Activate"
            visibility={showActivateEndUserModal}
            setVisibility={setShowActivateEndUserModal}
            submitAction={() => {
              handleActivateEndUser();
              setSelectedID(null);
              setShowActivateEndUserModal(false);
            }}
            title="Are you sure you want to activate this user?"
          />
        )}
      </div>
    </div>
  );
};

export default AdminEndUsersList;
