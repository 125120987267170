import React, { Fragment, useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Checkbox } from "@mui/material";
import { useHttp } from "../../hooks/useHttp";
import { ADMIN_ASSIGN_END_USERS_TABLE_COLUMNS } from "./assignEndUsers.constants";
import { getEndUsersRequest } from "../../api/modules/endUser";

const AssignEndUsersList = ({ client, setAssignedEndUsers }) => {
  const { http, isLoading } = useHttp();
  const [endUsers, setEndUsers] = useState([]);
  const [checkedEndUsers, setCheckedEndUsers] = useState([]);

  const getEndUsers = () => {
    http(() => {
      getEndUsersRequest().then((res) => setEndUsers(res));
    });
  };

  useEffect(() => {
    getEndUsers();
  }, []);

  useEffect(() => {
    if (!client || !endUsers) return;

    const initialCheckedEndUsers = endUsers
      .filter((endUser) => endUser.connectedAUsers?.includes(client.id))
      .map((endUser) => {
        return endUser.id;
      });
    setCheckedEndUsers(initialCheckedEndUsers);
  }, [client, endUsers]);

  const handleClickAssignments = (endUserId) => {
    const index = checkedEndUsers.indexOf(endUserId);
    if (index > -1) {
      setCheckedEndUsers((prev) => prev.filter((e) => e !== endUserId));
    } else {
      setCheckedEndUsers([...checkedEndUsers, endUserId]);
    }
  };
  useEffect(() => {
    setAssignedEndUsers(checkedEndUsers);
  }, [checkedEndUsers]);

  const columns = [
    ...ADMIN_ASSIGN_END_USERS_TABLE_COLUMNS,
    {
      field: "isAssigned",
      headerName: "Assigned?",
      sortable: true,
      filterable: true,
      headerAlign: "center",
      width: 100,
      renderCell: (params) => (
        <Checkbox
          checked={checkedEndUsers.includes(params.id)}
          onChange={() => handleClickAssignments(params.id)}
        />
      ),
    },
  ];

  const endUsersRows = endUsers.map((endUser) => ({
    ...endUser,
    isAssigned: checkedEndUsers.includes(endUser.id),
  }));

  return (
    <>
      <div className="flex flex-col">
        <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div className="shadowBox overflow-hidden ">
              <div style={{ width: "100%" }}>
                <DataGrid
                  loading={isLoading}
                  style={{ border: "0", padding: "10px 20px" }}
                  rows={endUsersRows || []}
                  columns={columns}
                  checkboxSelection={false}
                  pageSize={10}
                  autoHeight={true}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AssignEndUsersList;
