import React, { useState, useEffect } from "react";
import {
  fetchModels,
  searchModels,
  fetchModelsEndUser,
  fetchModelsEndUserByArchitect,
  assignModelToEndUsers,
  assignModelToUsers,
} from "../../../redux/modules/models/actions";
import { fetchClients } from "../../../redux/modules/clients/actions";

import { useSelector, useDispatch } from "react-redux";
import SearchField from "./SearchField";

import Pagination from "./Pagination";
import ListElement from "./ListElement";
import AssignUsersToModelModal from "../modals/AssignUsersToModelModal";

const ModelsList = (props) => {
  const [images] = React.useState([]);
  const dispatch = useDispatch();
  const { models } = useSelector((state) => state.modelData);
  const { clients } = useSelector((state) => state.clientsData);
  const [showModal, setShowModal] = useState(false);

  const search = (input, filters) => {
    setPage(1);
    if (!props.endUserModels) {
      dispatch(
        searchModels({
          search: input,
          filters: filters,
          page: 0,
          userId: props.userId,
        })
      );
    } else {
      if (props.architect) {
        dispatch(
          fetchModelsEndUserByArchitect({
            search: input,
            filters: filters,
            page: 0,
            userId: props.userId,
          })
        );
      } else {
        dispatch(
          fetchModelsEndUser({
            search: input,
            filters: filters,
            page: 0,
            userId: props.userId,
          })
        );
      }
    }
  };

  const handlePage = (page) => {
    setPage(page);
    if (!props.endUserModels) {
      if (searchValue) {
        dispatch(
          searchModels({
            search: searchValue,
            filters: filters,
            page: page - 1,
            userId: props.userId,
          })
        );
      } else {
        dispatch(fetchModels({ userId: props.userId, page: page - 1 }));
      }
    } else {
      if (props.architect) {
        dispatch(
          fetchModelsEndUserByArchitect({
            search: searchValue,
            filters: filters,
            page: page - 1,
            userId: props.userId,
          })
        );
      } else {
        dispatch(
          fetchModelsEndUser({
            search: searchValue,
            filters: filters,
            page: page - 1,
            userId: props.userId,
          })
        );
      }
    }
  };

  const [filters, setFilters] = useState({});
  const [page, setPage] = useState(1);
  const [searchValue, setSearchValue] = useState("");

  useEffect(() => {
    window.scrollTo(0, 0);
    if (!props.endUserModels) {
      dispatch(fetchModels({ userId: props.userId, page: 0 }));
      dispatch(fetchClients());
    } else {
      if (props.architect) {
        dispatch(
          fetchModelsEndUserByArchitect({
            page: 0,
            userId: props.userId,
          })
        );
      } else {
        dispatch(
          fetchModelsEndUser({
            page: 0,
            userId: props.userId,
          })
        );
      }
    }

    // dispatch(updateUserData());

    // do anything only one time if you pass empty array []
    // keep in mind, that component will be rendered one time (with default values) before we get here
  }, []);

  const [selectedModel, setSelectedModel] = useState("");

  const onModalSubmit = ({
    modelId,
    checkedEndUsersIds,
    checkedClientsIds,
  }) => {
    dispatch(
      assignModelToEndUsers({
        modelId: modelId,
        endUserIds: checkedEndUsersIds,
      })
    );
    dispatch(
      assignModelToUsers({ modelId: modelId, userIds: checkedClientsIds })
    );

    setShowModal(false);
  };

  return (
    <>
      <SearchField
        action={search}
        showFilterButton={true}
        setFilters={setFilters}
        filters={filters}
        searchValue={searchValue}
        setSearchValue={setSearchValue}
      />
      <div className="w-full flex flex-wrap justify-center lg:justify-start items-center">
        {(models?.models || []).map((item, i) => {
          return (
            <ListElement
              clients={clients}
              showModal={showModal}
              setShowModal={setShowModal}
              model={item}
              images={images}
              key={i}
              extraLink={props.extraLink}
              setSelectedModel={setSelectedModel}
            />
          );
        })}
      </div>
      <Pagination
        pageSize={models.pageSize}
        totalPages={models.totalPages}
        total={models.total}
        page={page}
        setPage={handlePage}
      />{" "}
      {showModal && (
        <AssignUsersToModelModal
          model={selectedModel}
          onSubmit={onModalSubmit}
        />
      )}
    </>
  );
};

export default ModelsList;
