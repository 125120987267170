import React from "react";
import { MenuItem, Select } from "@mui/material";

const ModelFormCustomFields = ({ formikProps, customFields }) => {
  return (customFields || []).map((customField, index) => {
    return (
      <div
        key={index}
        className="w-full flex items-center justify-center flex-col sm:flex-row "
      >
        <div className="w-full px-2">
          <h3 className="text-lg flex itmes-start font-medium leading-6 text-gray-900">
            {customField.name}
            <span className="text-xs text-red-500">*</span>
          </h3>
          <span className="text-xs text-blue-400">Mandatory field</span>

          <div
            className={`w-full flex justify-center items-center px-7 border border-fade rounded-4xl my-4`}
          >
            {/* TODO: Move hard-coded string to a constant */}
            {customField.type === "Dropdown List" && (
              <Select
                multiple={customField.isMultiple}
                name={`customFields_${customField._id}`}
                style={{ width: "100%" }}
                value={formikProps?.values?.[`customFields_${customField._id}`]}
                onChange={formikProps.handleChange}
              >
                {customField?.dropdownList?.map((option) => (
                  <MenuItem value={option} key={option}>
                    {option}
                  </MenuItem>
                ))}
              </Select>
            )}

            {/* TODO: Move hard-coded string to a constant */}
            {customField.type === "Number" && (
              <input
                type={"number"}
                name={`customFields_${customField._id}`}
                onChange={formikProps.handleChange}
                value={formikProps?.values?.[`customFields_${customField._id}`]}
                placeholder={`Number of ${customField.name}`}
                min={1}
                className={`flex flex-1 appearance-none w-1/2 py-5 leading-tight focus:outline-none`}
              />
            )}

            {/* TODO: Move hard-coded string to a constant */}
            {customField.type === "String" && (
              <input
                type={"text"}
                name={`customFields_${customField._id}`}
                onChange={formikProps.handleChange}
                placeholder={`${customField.name}`}
                value={formikProps?.values?.[`customFields_${customField._id}`]}
                className={`flex flex-1 appearance-none w-1/2 py-5 leading-tight focus:outline-none`}
              />
            )}
          </div>
          <p className="text-xs text-red-500">
            {formikProps.errors?.[`customFields_${customField._id}`]}
          </p>
        </div>
      </div>
    );
  });
};

export default ModelFormCustomFields;
