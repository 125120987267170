import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import logoMarket from "../../../assets/images/logo-market.png";
import { useDispatch } from "react-redux";
import { MenuItem, Select } from "@mui/material";
import { fetchMarketModels } from "../../../redux/modules/models/actions";
import MainButton from "../buttons/MainButton";
import { getCategoriesRequest } from "../../../api/modules/categories";
import { useHttp } from "../../../hooks/useHttp";
import { getFiltersBySubcategoryId } from "../../../api/modules/customFields";
import { Formik } from "formik";
import SidebarFiltersCustomFields from "../forms/SidebarFiltersCustomFields";
import * as Yup from "yup";

const SidebarMarketPlace = (props) => {
  const dispatch = useDispatch();
  const { http, isLoading } = useHttp();

  const [customFields, setCustomFields] = useState([]);
  const [categories, setCategories] = React.useState([]);
  const [subcategories, setSubcategories] = React.useState([]);

  useEffect(() => {
    http(() => getCategoriesRequest()).then((res) => setCategories(res));
  }, []);

  const getCustomFieldsBySubCategoryId = async (subCategoryId) => {
    return http(() => getFiltersBySubcategoryId(subCategoryId, true));
  };

  const getInitialValuesForNumbers = (customFields) => {
    const _initialValues = {};

    customFields?.map((customField) => {
      if (customField.type !== "Number") {
        return;
      }

      const numberMaxValue = Math.max(customField.values);
      _initialValues[`customFields_${customField._id}`] = [0, numberMaxValue];
    });

    return _initialValues;
  };

  const onCategoryChange = (e) => {
    const catId = e.target.value;
    const catObject = (categories || []).find((cat) => cat.id === catId);

    setSubcategories(catObject?.subcategories || []);
    setCustomFields([]);
  };

  const onSubcategoryChange = async (e, props) => {
    const subCatId = e.target.value;
    setCustomFields([]);

    if (!subCatId) {
      return;
    }

    const res = await getCustomFieldsBySubCategoryId(subCatId);
    setCustomFields(res.filters);

    const numbersInitialValues = getInitialValuesForNumbers(res.filters);

    for (const [key, value] of Object.entries(numbersInitialValues)) {
      props.setFieldValue(key, value);
      props.setFieldTouched(key, true);
    }
  };

  const prepareCustomFields = (formFields) => {
    const _customFields = [];
    Object.keys(formFields).map((field) => {
      if (!field.startsWith("customFields_")) {
        return;
      }

      const customFieldId = field.replace("customFields_", "");

      const customField = customFields.find(
        (customField) => customField._id === customFieldId
      );

      if (!customField) {
        return;
      }

      _customFields.push({
        _id: customFieldId,
        value: formFields[field],
        type: customField.type,
      });
    });

    return _customFields;
  };

  const onSubmit = (data) => {
    const _customFields = prepareCustomFields(data);
    console.log({ _customFields });

    dispatch(
      fetchMarketModels({
        sortBy: "createdAt",
        sortValue: "desc",
        search: "",
        filters: { ...data, customFields: _customFields },
        page: 0,
      })
    );
  };

  return (
    !props.hideSideMenu && (
      <>
        <div
          style={{ zIndex: "99" }}
          className={`fixed top-0 bottom-0 lg:left-0 ${props.showSidebar} overflow-y-auto flex-row flex-nowrap  shadow-xl bg-white w-64 px-6 transition-all duration-300`}
        >
          <NavLink to="/models" className=" text-center w-full inline-block">
            <div className="logo-container">
              <img src={logoMarket} className={"w-32 lg:mx-12"} />
            </div>
          </NavLink>
          <div
            className="flex flex-col overflow-y-auto items-stretch flex-nowrap px-0 relative py-2"
            style={{ maxHeight: "calc(100vh - 70px)" }}
          >
            <br />
            <div className="flex flex-col flex-1">
              <Formik
                validateOnMount={true}
                initialValues={{}}
                onSubmit={onSubmit}
                validationSchema={Yup.object().shape({
                  category: Yup.string().required(),
                })}
              >
                {(props) => (
                  <form onSubmit={props.handleSubmit}>
                    <div className="w-full  items-center justify-center flex-row ">
                      <div className="w-full px-2">
                        <h3 className="text-lg flex itmes-start font-medium leading-6 text-gray-900">
                          Category
                        </h3>
                        <div
                          className={`w-full flex justify-center items-center px-7 border border-fade rounded-4xl my-4`}
                        >
                          <Select
                            name={"category"}
                            style={{ width: "100%" }}
                            value={props.values?.category}
                            label="Category"
                            onChange={(e) => {
                              props.handleChange(e);
                              onCategoryChange(e);
                            }}
                          >
                            {categories?.map((cat) => (
                              <MenuItem value={cat.id} key={cat.id}>
                                {cat.name}
                              </MenuItem>
                            ))}
                          </Select>
                        </div>
                      </div>

                      {!!subcategories?.length && (
                        <div className="w-full px-2">
                          <h3 className="text-lg flex itmes-start font-medium leading-6 text-gray-900">
                            Subcategory
                          </h3>
                          <div
                            className={`w-full flex justify-center items-center px-7 border border-fade rounded-4xl my-4`}
                          >
                            <Select
                              name={"subcategory"}
                              style={{ width: "100%" }}
                              placeholder={"Select a subcategory"}
                              value={props.values?.subcategory}
                              label="Subcategory"
                              inputProps={{ "aria-label": "Without label" }}
                              onChange={async (e) => {
                                props.handleChange(e);
                                await onSubcategoryChange(e, props);
                              }}
                            >
                              {subcategories.map((subcat) => (
                                <MenuItem value={subcat._id} key={subcat._id}>
                                  {subcat.name}
                                </MenuItem>
                              ))}
                            </Select>
                          </div>
                        </div>
                      )}

                      <SidebarFiltersCustomFields
                        formikProps={props}
                        customFields={customFields || []}
                      />

                      <br />

                      <div className="flex justify-center">
                        <MainButton
                          title="Apply"
                          type={"submit"}
                          isLoading={isLoading}
                          disabled={isLoading || !props.isValid}
                          width="w-full"
                        />
                      </div>
                    </div>
                  </form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </>
    )
  );
};

export default SidebarMarketPlace;
